import type { ReactElement } from "react";

export const MiniPlayerEnterToLeft = (): ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3887 8.17902H27.472C28.5013 8.17902 29.3887 8.99592 29.3887 10.0679V24.2901C29.3887 25.3621 28.5013 26.179 27.472 26.179H7.30534C6.27602 26.179 5.38867 25.3621 5.38867 24.2901V18.179C4.2841 18.179 3.38867 17.2836 3.38867 16.179V8.17902C3.38867 7.07445 4.2841 6.17902 5.38867 6.17902H15.3887C16.4932 6.17902 17.3887 7.07445 17.3887 8.17902ZM27.3887 10.179V24.179H7.38867V18.179H15.3887C16.4932 18.179 17.3887 17.2836 17.3887 16.179V10.179H27.3887ZM15.3887 16.179H5.38867V8.17902H15.3887V16.179Z"
      fill="currentColor"
    />
  </svg>
);
