import type { ReactElement } from "react";

import { ChromeClose } from "@talktype/icons/src/ChromeClose";

import styles from "./windowsCloseButton.module.scss";
import { StandardButton } from "../../StandardButton";

type WindowsCloseButtonProps = {
  label: string;
  onClose: () => void;
};

export const WindowsCloseButton = ({
  label,
  onClose,
}: WindowsCloseButtonProps): ReactElement => (
  <StandardButton
    colour="neutral"
    hierarchy="link"
    size="sm"
    style="icon-only"
    label={label}
    mainIcon={<ChromeClose />}
    elementProps={{
      onClick: onClose,
      className: styles.closeButton,
      tabIndex: -1, // Match native OS control button behaviour
    }}
  />
);
