import { eventChannel, type EventChannel } from "redux-saga";

import { noOp } from "@carescribe/utilities/src/fp";

/**
 * Create Zoom Level Event Channel
 *
 * Creates an event channel that fires whenever the devicePixelRatio
 * changes.
 */
export const createZoomEventChannel = (): EventChannel<true> =>
  eventChannel<true>((emit) => {
    let remove: () => void = noOp;

    /**
     * Because it uses a media query match, we have to keep updating
     * the listener function with the new devicePixelRation value
     *
     * @link{ https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio#monitoring_screen_resolution_or_zoom_level_changes }
     */
    const updatePixelRatio = (): void => {
      remove();

      const mediaQuery = `(resolution: ${window.devicePixelRatio}dppx)`;
      const media = matchMedia(mediaQuery);
      media.addEventListener("change", updatePixelRatio);

      remove = (): void =>
        media.removeEventListener("change", updatePixelRatio);

      emit(true);
    };

    updatePixelRatio();

    return remove;
  });
