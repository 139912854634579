import type { Ipc } from "@talktype/types";
import type { EventChannel, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";

import { requestToggleDictation } from "@talktype/actions";
import { InteractionMethod } from "@talktype/analytics/src/types/InteractionMethod";
import {
  defaultDictationHotkey,
  toggleDictationId,
} from "@talktype/config/src/trayMenuItems";
import {
  loginComplete,
  logoutComplete,
} from "@talktype/user/src/sagas/actions";
import { getIpc } from "@talktype/utilities/src/getIpc";

export const createDictationHotkeyPressedChannel = (
  ipc: Ipc
): EventChannel<true> =>
  eventChannel((emit) => {
    const unsubscribe = ipc?.system?.hotkey?.subscribe?.v1?.((_, id) => {
      if (id === toggleDictationId) {
        emit(true);
      }
    });

    return () => unsubscribe?.();
  });

/**
 * Register Dictation Hotkey
 *
 * - Registers the dictation hotkey on login and removes it on logout.
 * - Listens for the hotkey press and dispatches the relevant action.
 */
export const registerDictationHotkey = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  const subscribe = ipc?.system?.hotkey?.subscribe?.v1;
  const add = ipc?.system?.hotkey?.add?.v1;
  const remove = ipc?.system?.hotkey?.remove?.v1;

  if (!subscribe || !add || !remove) {
    return;
  }

  const channel: SagaReturnType<typeof createDictationHotkeyPressedChannel> =
    yield call(createDictationHotkeyPressedChannel, ipc);

  yield takeEvery(channel, function* () {
    yield put(
      requestToggleDictation({
        interactionMethod: InteractionMethod.keyboard,
      })
    );
  });

  yield takeEvery(loginComplete, function* () {
    yield call(add, toggleDictationId, defaultDictationHotkey);
  });

  yield takeEvery(logoutComplete, function* () {
    yield call(remove, toggleDictationId);
  });
};
