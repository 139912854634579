import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities/src/getIpc";

import { setPlatform } from "../reducers/system";

/**
 * Saga responsible for detecting the platform of the user's device.
 *
 * When the desktop IPC is available, uses this for determining the platform
 * as it is more accurate than the web platform (relies on the user agent).
 */
export const getPlatformFromDesktop = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  const getDesktopPlatform = ipc?.system?.process?.getPlatform?.v1;

  if (!getDesktopPlatform) {
    return;
  }

  const desktopPlatform: SagaReturnType<typeof getDesktopPlatform> = yield call(
    getDesktopPlatform
  );
  const platform =
    desktopPlatform === "darwin"
      ? "mac"
      : desktopPlatform === "win32"
      ? "win"
      : null;

  yield put(setPlatform(platform));
};
