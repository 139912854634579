import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call, delay } from "redux-saga/effects";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";
import { isTouchDevice as getIsTouchDevice } from "@carescribe/utilities/src/browser";
import { waitUntilNextAnimationFrame } from "@carescribe/utilities/src/waitUntilNextAnimationFrame";

import { logWarning } from "../../utils/log";
import { editorLoaded, startDictating } from "../actions";
import { getEditor } from "../utils";

/**
 * Manages the editor's focus:
 *
 * - Focus when loaded
 * - Focus when dictation starts
 */
export const manageFocus = function* (): SagaIterator<void> {
  yield takeEvery(
    [editorLoaded, startDictating],
    function* (): SagaIterator<void> {
      /**
       * Wait for a short while before checking if the editor is still there.
       *
       * Currently, there is the possibility that the editor mounts briefly
       * while the window layout is being determined. This can otherwise lead to
       * an attempt at focusing an editor that has already unmounted, leading to
       * an error.
       */
      yield delay(100);

      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);
      const isTouchDevice: SagaReturnType<typeof getIsTouchDevice> = yield call(
        getIsTouchDevice
      );

      const shouldFocus =
        editor &&
        /*
         * On touch devices, focusing the editor and subsequently pressing
         * the dictation button may cause the virtual keyboard to appear.
         * This behaviour is undesired as the user should be able to toggle
         * dictation on and off without the virtual keyboard appearing.
         */
        !isTouchDevice;

      if (!shouldFocus) {
        return;
      }

      /**
       * A quirky thing about focus is that when elements take focus, a call to
       * focus another element on the same frame will be ignored. This can
       * happen for instance when pressing the dictation button to start
       * dictating. Therefore, we wait until the next animation frame to ensure
       * our call to focus the editor is respected.
       */
      yield call(waitUntilNextAnimationFrame);

      try {
        yield call(focusEditor, editor);
      } catch (error) {
        yield call(logWarning, "Failed to focus the editor", { error });
      }
    }
  );
};
