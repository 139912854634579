import type { MoveSelectionInstruction } from "@carescribe/types";
import type { Result } from "@talktype/types";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/dictate-to-app");

export const requestCopyDictationHistoryEntry = action<
  { resultId: string },
  "requestCopyDictationHistoryEntry"
>("requestCopyDictationHistoryEntry");

export const requestMoveSelection = action<
  MoveSelectionInstruction,
  "request-move-selection"
>("request-move-selection");

export const selectionMoved = action<{ success: boolean }, "selection-moved">(
  "selection-moved"
);

export const requestDictateToAppPrecedingText = action<
  Result,
  "request-dictate-to-app-preceding-text"
>("request-dictate-to-app-preceding-text");

export const broadcastDictateToAppPrecedingText = action<
  string,
  "broadcast-dictate-to-app-preceding-text"
>("broadcast-dictate-to-app-preceding-text");
