import type { RootState } from "@talktype/store";
import type { RegularLayoutProps } from "@talktype/ui/src/RegularLayout";

import { connect } from "react-redux";

import { selectDrawerIsOpen } from "@talktype/preferences/src/reducers/";
import { RegularLayout as Component } from "@talktype/ui/src/RegularLayout";

import { AppControls } from "./AppControls";
import { DictationControls } from "./DictationControls";
import { Drawer } from "./Drawer";
import { Editor } from "./Editor";
import { FormattingToolbar } from "./FormattingToolbar";
import { HeaderBar } from "./HeaderBar";
import { Notifications } from "./Notifications";
import { PWAInstallPrompt } from "./PWAInstallPrompt";

const mapStateToProps = (
  state: RootState
): Pick<
  RegularLayoutProps,
  | "prompt"
  | "header"
  | "drawer"
  | "formattingToolbar"
  | "editor"
  | "dictationControls"
  | "notifications"
> => ({
  drawer: {
    component: <Drawer />,
    isOpen: selectDrawerIsOpen(state),
  },
  prompt: <PWAInstallPrompt />,
  header: (
    <HeaderBar style="default" colour="neutral">
      <AppControls />
    </HeaderBar>
  ),
  formattingToolbar: <FormattingToolbar />,
  editor: <Editor />,
  dictationControls: <DictationControls />,
  notifications: <Notifications />,
});

export const RegularLayout = connect(mapStateToProps)(Component);
