import type { ReactElement } from "react";

export const ChromeClose = (): ReactElement => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.970921 9.017L0.970822 9.01709L0.975768 9.02203C1.08052 9.12679 1.21186 9.18185 1.36008 9.18185C1.50962 9.18185 1.64283 9.12787 1.74866 9.02203L4.99993 5.77076L8.25121 9.02203L8.25106 9.02218L8.25747 9.02801L8.30435 9.07063L8.31513 9.08043L8.32734 9.08839C8.4205 9.14914 8.52435 9.18185 8.63552 9.18185C8.78517 9.18185 8.91848 9.12767 9.02243 9.01938L9.02248 9.01943L9.02581 9.01577L9.06843 8.96889L9.07823 8.95811L9.08619 8.9459C9.14695 8.85274 9.17965 8.7489 9.17965 8.63772C9.17965 8.48874 9.12596 8.35596 9.01864 8.25221L5.76856 5.00213L9.01984 1.75086C9.1259 1.6448 9.18392 1.51261 9.18392 1.36228C9.18392 1.21207 9.12787 1.07945 9.01861 0.976667C8.91522 0.871015 8.78334 0.81815 8.63552 0.81815C8.48655 0.81815 8.35377 0.871843 8.25002 0.979151L4.99993 4.22924L1.74866 0.977965L1.74283 0.972132L1.73649 0.966852L1.68534 0.92423L1.67861 0.918619L1.67137 0.913682C1.57821 0.850167 1.4728 0.81815 1.36008 0.81815C1.21186 0.81815 1.08052 0.873216 0.975768 0.977965L0.969935 0.983798L0.964654 0.990135L0.922033 1.04128L0.921645 1.04096L0.913757 1.052C0.848046 1.14399 0.815952 1.24952 0.815952 1.36228C0.815952 1.51181 0.869938 1.64503 0.975768 1.75086L4.22704 5.00213L0.975768 8.25341C0.871018 8.35815 0.815952 8.48949 0.815952 8.63772C0.815952 8.7827 0.872634 8.91085 0.970921 9.017Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.363701"
    />
  </svg>
);
