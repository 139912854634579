import type { SystemState } from "../../../types/SystemState";
import type { PayloadAction } from "@reduxjs/toolkit";

type SetWindowMaximised = (
  state: SystemState,
  action: PayloadAction<boolean>
) => void;

export const setWindowMaximised: SetWindowMaximised = (state, { payload }) => {
  state.window.maximised = payload;
};
