import type { TranscriptSegment } from "@talktype/types";
import type { Editor } from "slate";

import { getSelectionInlineStyleMarks } from "./getSelectionInlineStyleMarks";

export type ApplySelectionMarksArgs = {
  editor: Editor;
  segment: TranscriptSegment;
  inProgress: boolean;
};

/**
 * Apply the selection marks to the given segment.
 */
export const applySelectionMarks = ({
  editor,
  segment,
  inProgress,
}: ApplySelectionMarksArgs): TranscriptSegment =>
  segment.type === "text"
    ? {
        ...segment,
        ...getSelectionInlineStyleMarks(editor),
        inProgress,
      }
    : segment;
