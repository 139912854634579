import type { TextNode } from "@talktype/types";

import { defaultInlineStyleProperties } from "./defaultInlineStyleProperties";
import { defaultInProgressProperties } from "./defaultInProgressProperties";

export const defaultTextNodeProperties: TextNode = {
  ...defaultInlineStyleProperties,
  ...defaultInProgressProperties,
  type: "text",
  text: "",
};
