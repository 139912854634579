import type { EditorType } from "../../editor";
import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  broadcastEditor,
  editorLoaded,
  editorUnloaded,
  editorRestored,
  requestEditor,
} from "../actions";

/**
 * Tracks and broadcasts the currently loaded editor.
 */
export const trackEditor = function* (): SagaIterator<void> {
  let editor: EditorType | null = null;

  yield takeEvery([editorLoaded, editorRestored], function* ({ payload }) {
    editor = payload;
  });

  yield takeEvery(editorUnloaded, function* () {
    editor = null;
  });

  yield takeEvery(requestEditor, function* () {
    yield put(broadcastEditor(editor));
  });
};
