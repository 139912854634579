import { createSelector } from "@reduxjs/toolkit";

import { selectDictationMode, selectDictationStatus } from "@talktype/editor";
import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";

/**
 * Returns true if:
 * - The active app is not the TalkType app
 * - Dictation mode is "anywhere"
 * - Dictation is active
 */
export const selectShouldShowDictatingToToast = createSelector(
  selectActiveApp,
  selectDictationMode,
  selectDictationStatus,
  (activeApp, dictationMode, dictationStatus) =>
    !activeApp.isSelf &&
    dictationMode === "anywhere" &&
    dictationStatus === "active"
);
