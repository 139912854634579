import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getPreviousLeafText } from "@talktype/utilities/src/slate/getPreviousLeafText";

import { getEditor } from "../utils";

/**
 * Handles requests for the preceding text in the editor.
 */
export const getEditorPrecedingText = function* (): SagaIterator<string> {
  const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

  if (!editor) {
    return "";
  }

  const { selection } = editor;

  if (!selection) {
    return "";
  }

  const precedingText: SagaReturnType<typeof getPreviousLeafText> = yield call(
    getPreviousLeafText,
    editor,
    selection
  );

  return precedingText;
};
