import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationModeMenuProps } from "@talktype/ui/src/DictationModeMenu";

import { connect } from "react-redux";

import { requestSetDictationMode } from "@talktype/editor";
import {
  selectDictationMode,
  selectDictationStatus,
} from "@talktype/editor/src/reducers";
import { selectDictateToAppIsEnabled } from "@talktype/feature-flags/src/reducers/selectors";
import { DictationModeMenu as Component } from "@talktype/ui/src/DictationModeMenu";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

const mapStateToProps = (
  state: RootState
): Pick<DictationModeMenuProps, "mode" | "status" | "disabled"> => ({
  mode: selectDictationMode(state),
  status: selectDictationStatus(state),
  disabled: !selectDictateToAppIsEnabled(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<
  DictationModeMenuProps,
  "handleChangeToTalkType" | "handleChangeToAnywhere"
> => ({
  handleChangeToTalkType: ({ event }): void => {
    dispatch(
      requestSetDictationMode({
        dictationMode: "talktype",
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    );
  },
  handleChangeToAnywhere: ({ event }): void => {
    dispatch(
      requestSetDictationMode({
        dictationMode: "anywhere",
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    );
  },
});

export const DictationModeMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
