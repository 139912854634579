import type { EditorType } from "../../editor";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import {
  requestClearInProgressContent,
  clearedInProgressContent,
  editorRestored,
} from "../actions";
import { getEditor } from "../utils";

/**
 * Manages the clearing of in-progress content in the editor.
 *
 * When processing an in-progress result, there is the possibility that a
 * more up-to-date or accurate result is received. In such cases, any work
 * done so far must be undone, and the editor state must revert to the point
 * before processing began. Otherwise, we may for example end up with duplicate
 * text or incorrect styling.
 *
 * The way this is achieved is via "snapshotting" aka taking a copy of the
 * editor state at a given point in time so that we may later return to it.
 *
 * When clearing is requested, we first check if a snapshot exists for the
 * given `resultId`. If it does, the editor state is restored to this snapshot.
 * If no snapshot exists, a new snapshot is taken of the current editor state.
 */
export const manageClearInProgressContent = function* (): SagaIterator<void> {
  let snapshot: {
    editor: Pick<EditorType, "children" | "selection" | "marks"> | null;
    resultId: string;
  } = {
    editor: null,
    resultId: "",
  };

  yield takeEvery(
    requestClearInProgressContent,
    function* ({ payload: { resultId } }) {
      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

      if (!editor) {
        yield put(clearedInProgressContent());
        return;
      }

      const { resultId: snapshotResultId, editor: snapshotEditor } = snapshot;

      const snapshotAvailable = snapshotResultId === resultId && snapshotEditor;
      if (snapshotAvailable) {
        editor.children = snapshotEditor.children;
        editor.selection = snapshotEditor.selection;
        editor.marks = snapshotEditor.marks;
        yield put(editorRestored(editor));
      } else {
        const { children, selection, marks } = editor;
        snapshot = {
          editor: { children, selection, marks },
          resultId,
        };
      }

      yield put(clearedInProgressContent());
    }
  );
};
