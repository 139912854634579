import type { ReactElement } from "react";

import { ArrowsClockwise } from "@phosphor-icons/react";

import { Alert } from "@talktype/ui/src/Alert";

import { messages } from "./messages";
export type UpdateAvailableToastProps = {
  onClick: () => void;
  dismiss: () => void;
};

export const UpdateAvailableToast = ({
  onClick,
  dismiss,
}: UpdateAvailableToastProps): ReactElement => (
  <Alert
    colour="brand"
    hierarchy="primary"
    format="stacked"
    icon={<ArrowsClockwise />}
    onClose={dismiss}
    label={{ text: messages.label, onClick }}
    primaryText={messages.primaryText}
    secondaryText={messages.secondaryText}
  />
);
