import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery, select } from "redux-saga/effects";

import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";
import { appIsEqual } from "@talktype/utilities/src/appIsEqual";

import {
  requestAddResultToEditor,
  requestSendResult,
  requestSendResultToDesktopApp,
  resultSent,
} from "./actions";

export const sendResult = function* (): SagaIterator<void> {
  yield takeEvery(
    requestSendResult,
    function* ({ payload: result }): SagaIterator<void> {
      if (result.targetApp.isSelf) {
        yield put(requestAddResultToEditor(result));
        return;
      }

      const activeApp: SagaReturnType<typeof selectActiveApp> = yield select(
        selectActiveApp
      );

      const isActiveApp: SagaReturnType<typeof appIsEqual> = yield call(
        appIsEqual,
        result.targetApp,
        activeApp
      );

      if (!isActiveApp) {
        yield put(resultSent(result));
        return;
      }

      yield put(requestSendResultToDesktopApp(result));
    }
  );
};
