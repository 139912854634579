import type { TextNode } from "@talktype/types";

import { defaultTextNodeProperties } from "./entities/defaultLeafProperties";

export const textNode = (input?: Partial<TextNode> | string): TextNode => {
  const inputIsString = typeof input === "string";

  const properties = !inputIsString && input ? input : {};
  const text = inputIsString ? input : input?.text ?? "";
  const inProgress =
    "inProgress" in properties && typeof properties.inProgress === "boolean"
      ? properties.inProgress
      : defaultTextNodeProperties.inProgress;

  return {
    ...defaultTextNodeProperties,
    ...properties,
    text,
    inProgress,
  };
};
