import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { handleFormattingButtons } from "./handleFormattingButtons";
import { storeSelectionFormattingInRedux } from "./storeSelectionFormattingInRedux";

export const setUpFormattingBar = function* (): SagaIterator<void> {
  yield all(
    [handleFormattingButtons, storeSelectionFormattingInRedux].map(retry)
  );
};
