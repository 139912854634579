import type { KeyPress } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

import { pressEnter } from "../pressEnter/app";

export const pressKeys = (hotkeys: KeyPress[]) =>
  function* (): SagaIterator<boolean> {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    if (!ipc?.system?.controls?.pressKeys?.v1) {
      // The desired IPC method is not available, meaning that we have
      // incompatible FE/BE versions. In this case, fall back to the old method
      // that used to handle Enter key presses
      if (
        hotkeys.length === 1 &&
        hotkeys.find((hotkey) => hotkey.key === "Enter")
      ) {
        return yield call(pressEnter);
      }

      return false;
    }

    const wasSuccessful: SagaReturnType<
      typeof ipc.system.controls.pressKeys.v1
    > = yield call(ipc.system.controls.pressKeys.v1, hotkeys);

    return wasSuccessful;
  };
