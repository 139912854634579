import type { ReactElement } from "react";

import styles from "./macCloseButton.module.scss";

type MacCloseButtonProps = {
  label: string;
  onClose: () => void;
};

export const MacCloseButton = ({
  label,
  onClose,
}: MacCloseButtonProps): ReactElement => (
  <button
    aria-label={label}
    className={styles.closeButton}
    onClick={onClose}
    tabIndex={-1} // Match native OS control button behaviour
  />
);
