import type { ReactElement } from "react";

export const MiniPlayerExitBold = (): ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8465 13.2655C10.9404 13.3044 11.041 13.3244 11.1425 13.3244L13.9828 13.3245C14.0844 13.3246 14.185 13.3046 14.2788 13.2658C14.3727 13.2269 14.458 13.17 14.5298 13.0981C14.6017 13.0263 14.6586 12.941 14.6975 12.8471C14.7363 12.7533 14.7563 12.6527 14.7562 12.5511L14.7561 9.71082C14.7561 9.50568 14.6746 9.30893 14.5296 9.16387C14.3845 9.01881 14.1878 8.93731 13.9826 8.93731C13.7775 8.93731 13.5808 9.01879 13.4357 9.16385C13.2906 9.3089 13.2092 9.50564 13.2092 9.71078L13.2099 10.6849L11.0953 8.57078C10.9503 8.4258 10.7537 8.34434 10.5487 8.34434C10.3436 8.34433 10.147 8.42578 10.002 8.57075C9.85706 8.71572 9.77561 8.91235 9.77562 9.11738C9.77562 9.32241 9.85708 9.51904 10.0021 9.66402L12.1167 11.7782L11.1425 11.7775C11.0409 11.7775 10.9403 11.7975 10.8465 11.8363C10.7527 11.8752 10.6674 11.9322 10.5956 12.004C10.5237 12.0758 10.4668 12.1611 10.4279 12.2549C10.389 12.3488 10.369 12.4494 10.369 12.5509C10.369 12.6525 10.389 12.7531 10.4279 12.8469C10.4668 12.9408 10.5238 13.026 10.5956 13.0979C10.6674 13.1697 10.7527 13.2267 10.8465 13.2655Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09473 11.8242C8.95336 12.0312 8.8707 12.2816 8.8707 12.5512C8.8707 12.8415 8.96649 13.1094 9.12819 13.325H3.45984C2.81405 13.325 2.25082 12.8115 2.25082 12.1299L2.25081 9.25371C1.67993 9.13783 1.25024 8.6331 1.25024 8.02802L1.25024 4.02574C1.25024 3.33499 1.81021 2.77502 2.50096 2.77502L7.50381 2.77502C8.10889 2.77502 8.61362 3.20471 8.7295 3.77559L13.5489 3.77559C14.1947 3.77559 14.7579 4.28907 14.7579 4.97072V7.69507C14.542 7.53261 14.2735 7.43632 13.9825 7.43632C13.7135 7.43632 13.4638 7.51854 13.2571 7.65921V5.27645L8.75452 5.27645L8.75452 8.02802C8.75452 8.71877 8.19456 9.27873 7.50381 9.27873L3.75167 9.27873L3.75167 11.8242H9.09473ZM7.25366 4.27588L7.25367 7.77788L2.7511 7.77788L2.7511 4.27588L7.25366 4.27588Z"
      fill="currentColor"
    />
  </svg>
);
