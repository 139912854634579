import type { InlineStyleMarks } from "@talktype/types";
import type { Editor } from "slate";

import { setMark } from "@carescribe/slate/src/utils/setMark";

/**
 * Given a marks object, toggle the mark at the current selection.
 *
 * @example
 * // To toggle bold on
 * toggleMark(editor, 'bold', { bold: false, italic: false })
 */
export const toggleMark = <M extends Partial<InlineStyleMarks>>(
  editor: Editor,
  mark: keyof M extends string ? keyof M : never,
  marks: M
): Promise<void> => setMark(editor, mark, !marks[mark]);
