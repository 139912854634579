import type { RootState } from "@talktype/store";
import type { NotificationsProps } from "@talktype/ui/src/Notifications";
import type { ReactElement } from "react";

import { connect } from "react-redux";

import { selectToasts } from "@talktype/toasts/src/reducer/selectors/selectToasts";
import { Notifications as Component } from "@talktype/ui/src/Notifications";

import { Toast } from "./Toast";

const mapStateToProps = (state: RootState): NotificationsProps => ({
  toasts: selectToasts(state),
  renderToast: (toast): ReactElement => <Toast {...toast} />,
});

export const Notifications = connect(mapStateToProps)(Component);
