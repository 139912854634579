import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { waitUntilNextAnimationFrame } from "@carescribe/utilities/src/waitUntilNextAnimationFrame";

import { getEditorIsFocused } from "@talktype/editor/src/sagas/utils";

import { moveCursor } from "./utils/moveCursor";
import { requestCursorMove, movedCursor } from "../actions";

/**
 * Handles requests to modify the cursor position.
 */
export const manageCursor = function* (): SagaIterator<void> {
  yield takeEvery(requestCursorMove, function* ({ payload: direction }) {
    const editorIsFocused: SagaReturnType<typeof getEditorIsFocused> =
      yield call(getEditorIsFocused);

    // Moving cursor is only intended when the cursor is within the editor.
    if (!editorIsFocused) {
      yield put(movedCursor(false));
      return;
    }

    /**
     * Necessary to wait until next animation frame otherwise
     * can cause edge cases where the cursor is not moved.
     */
    yield call(waitUntilNextAnimationFrame);

    const moved: SagaReturnType<typeof moveCursor> = yield call(
      moveCursor,
      direction
    );

    yield put(movedCursor(moved));
  });
};
