import type { SupportedPlatform } from "@talktype/types/src/SupportedPlatform";
import type { ReactElement } from "react";

import { MacCloseButton } from "./MacCloseButton";
import { messages } from "./messages";
import { WindowsCloseButton } from "./WindowsCloseButton";

export type DictateAnywhereCloseWindowButtonProps = {
  platform: SupportedPlatform | null;
  onClose: () => void;
};

export const DictateAnywhereCloseWindowButton = ({
  platform,
  onClose,
}: DictateAnywhereCloseWindowButtonProps): ReactElement => {
  switch (platform) {
    case "mac":
      return <MacCloseButton label={messages.closeWindow} onClose={onClose} />;
    case "win":
    case null:
      return (
        <WindowsCloseButton label={messages.closeWindow} onClose={onClose} />
      );
  }
};
