import type { Platform } from "@carescribe/types/src/Platform";
import type { FormattingBarButtonName } from "@talktype/types";
import type { Messages } from "@talktype/types/src/Messages";

type FormattingToolbarMessages = Record<
  FormattingBarButtonName,
  Messages<{ platform: Platform; active: boolean }>
> & { copied: Messages };

export const messages: FormattingToolbarMessages = {
  bold: ({ platform, active }) => [
    "Bold",
    platform === "mac" ? "⌘ + B" : "Ctrl + B",
    active ? `"TalkType, Bold Off"` : `"TalkType, Bold On"`,
  ],

  italic: ({ platform, active }) => [
    "Italic",
    platform === "mac" ? "⌘ + I" : "Ctrl + I",
    active ? `"TalkType, Italic Off"` : `"TalkType, Italic On"`,
  ],

  underline: ({ platform, active }) => [
    "Underline",
    platform === "mac" ? "⌘ + U" : "Ctrl + U",
    active ? `"TalkType, Underline Off"` : `"TalkType, Underline On"`,
  ],

  heading: ({ platform, active }) => [
    "Heading",
    platform === "mac" ? "⌘ + H" : "Ctrl + H",
    active ? `"TalkType, Heading Off"` : `"TalkType, Heading On"`,
  ],

  undo: ({ platform }) => [
    "Undo",
    platform === "mac" ? "⌘ + Z" : "Ctrl + Z",
    `"TalkType, Undo"`,
  ],

  redo: ({ platform }) => [
    "Redo",
    platform === "mac" ? "⌘ + ⇧ + Z" : "Ctrl + ⇧ + Z",
    `"TalkType, Redo"`,
  ],

  clear: () => ["Clear canvas", `"TalkType, Clear All"`],

  copy: () => ["Copy to clipboard"],

  copied: ["Copied to clipboard!"],

  punctuation: () => ["Auto Punctuation"],

  submenu: () => ["More"],
};
