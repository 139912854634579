import type { InlineStyleMarks } from "@talktype/types";
import type { Editor } from "slate";

import { getInlineStyle } from "@talktype/utilities";
import { getInlineStyleSelectionMarks } from "@talktype/utilities/src/slate/getInlineStyleSelectionMarks";

import { defaultInlineStyleProperties } from "./entities/defaultInlineStyleProperties";

/**
 * Get the inline style marks that should be added to text at the
 * current selection.
 */
export const getSelectionInlineStyleMarks = (
  editor: Editor
): InlineStyleMarks => {
  // Get selection marks
  const marks = getInlineStyleSelectionMarks(editor);

  // Extract inline style marks or return default
  return marks ? getInlineStyle(marks) : defaultInlineStyleProperties;
};
