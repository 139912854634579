import type { SystemState } from "../types/SystemState";

export const createSystemState = (
  system: Partial<SystemState>
): SystemState => ({
  activeApp: { name: "TalkType", isSelf: true },
  window: { maximised: false },
  appVersion: null,
  buildId: null,
  platform: null,
  ...system,
});
