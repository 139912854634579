import type { AppDispatch, RootState } from "@talktype/store";
import type { MainMenuProps } from "@talktype/ui/src/MainMenu";

import { connect } from "react-redux";

import { noOp } from "@carescribe/utilities/src/fp";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { selectChameleonIsEnabled } from "@talktype/feature-flags/src/reducers/selectors";
import { MainMenu as Component } from "@talktype/ui/src/MainMenu";
import { selectTalkTypeDashboardUrl } from "@talktype/user/src/reducer/selectors/selectTalkTypeDashboardUrl";
import { requestLogout } from "@talktype/user/src/sagas/actions";

import { AutoDateFormattingField } from "./AutoDateFormattingField";
import { DictationLanguageField } from "./DictationLanguageField";
import { FilterProfanityField } from "./FilterProfanityField";
import { NumberFormattingField } from "./NumberFormattingField";
import { PinTalkTypeToFrontField } from "./PinTalkTypeToFrontField";
import { ThemeField } from "./ThemeField";
import { VersionDescription } from "./VersionDescription";

const mapStateToProps = (
  state: RootState
): Pick<
  MainMenuProps,
  "preferencesFields" | "dashboardUrl" | "canSubmitFeedback"
> => ({
  preferencesFields: (
    <>
      <ThemeField />
      <DictationLanguageField />
      <AutoDateFormattingField />
      <NumberFormattingField />
      <FilterProfanityField />
      {getAppContext() === "desktop" && <PinTalkTypeToFrontField />}
      <VersionDescription />
    </>
  ),
  dashboardUrl: selectTalkTypeDashboardUrl(state),
  canSubmitFeedback: selectChameleonIsEnabled(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<MainMenuProps, "logOut" | "submitFeedback"> => ({
  logOut: () => dispatch(requestLogout),

  /**
   * A hook for Chameleon to latch onto, so doesn't need
   * to dispatch anything
   */
  submitFeedback: noOp,
});

export const MainMenu = connect(mapStateToProps, mapDispatchToProps)(Component);
