import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, takeEvery } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

import { insertParagraph } from "../../utils/insertHandlers";
import { pressedEnter, requestPressEnter } from "../actions";
import { getEditor } from "../utils";

/**
 * Sets up a saga to handle the press enter command.
 *
 * How it is actioned depends on the current dictation mode:
 *
 * - In "talktype" dictation mode, it attempts to insert a paragraph
 * into the editor.
 * - Otherwise, it triggers the system's press enter control via IPC.
 */
export const setUpPressEnter = function* (): SagaIterator<void> {
  yield takeEvery(requestPressEnter, function* ({ payload: { target } }) {
    switch (target) {
      case "talktype": {
        const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

        if (!editor) {
          yield put(pressedEnter(false));
          return;
        }

        yield call(insertParagraph, editor);
        yield put(pressedEnter(true));
        return;
      }

      case "app": {
        const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

        if (!ipc?.system?.controls?.pressEnter?.v1) {
          yield put(pressedEnter(false));
          return;
        }

        yield call(ipc.system.controls.pressEnter.v1);
        yield put(pressedEnter(true));
      }
    }
  });
};
