import type { MenuItem } from "@talktype/types";
import type { ReactElement } from "react";

import { ArrowSquareOut } from "@phosphor-icons/react";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./item.module.scss";
import { IconContainer } from "../../IconContainer";
import { Link } from "../../Link";

export const Item = ({ label, Icon, ...item }: MenuItem): ReactElement => {
  const content = (
    <>
      <IconContainer className={styles.icon}>
        <Icon />
      </IconContainer>

      {label}
    </>
  );

  const platform = getPlatform();

  const shortcut =
    "shortcut" in item && item.shortcut ? item.shortcut({ platform }) : null;

  return item.type === "button" ? (
    <button
      className={classNames(
        styles.item,
        createSelectorClassName(`main-menu--item--${item.id}`, "interactive")
      )}
      onClick={item.onClick}
    >
      {content} {shortcut && <kbd className={styles.shortcut}>{shortcut}</kbd>}
    </button>
  ) : (
    <Link
      className={classNames(
        styles.item,
        createSelectorClassName(`main-menu--item--${item.id}`, "interactive")
      )}
      href={item.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {content}

      <IconContainer className={classNames(styles.icon, styles.linkIndicator)}>
        <ArrowSquareOut />
      </IconContainer>
    </Link>
  );
};
