import { createSelector } from "@reduxjs/toolkit";

import { selectDictationMode } from "@talktype/editor";
import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";

/**
 * Returns the app to assign to a new result based on the current dictation
 * mode.
 *
 * If the dictation mode is "anywhere", the active app is returned.
 * Otherwise, the TalkType app is returned.
 */
export const selectAssignableApp = createSelector(
  selectDictationMode,
  selectActiveApp,
  (dictationMode, activeApp) =>
    dictationMode === "talktype"
      ? { name: "TalkType", isSelf: true }
      : activeApp
);
