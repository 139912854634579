import { createSelector } from "@reduxjs/toolkit";

import { selectDictationMode } from "@talktype/editor";
import { selectCompactLayoutIsEnabled } from "@talktype/feature-flags/src/reducers/selectors";
import { selectUserIsLoggedIn } from "@talktype/user/src/reducer/selectors/selectUserIsLoggedIn";

export const selectLayout = createSelector(
  selectUserIsLoggedIn,
  selectDictationMode,
  selectCompactLayoutIsEnabled,
  (isLoggedIn, dictationMode, compactLayoutIsEnabled) => {
    if (!isLoggedIn || !compactLayoutIsEnabled) {
      return "regular";
    }

    return dictationMode === "anywhere" ? "compact" : "regular";
  }
);
