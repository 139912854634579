import type { Void } from "@carescribe/types";
import type { MenuItem } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import {
  ChatCenteredText,
  Gear,
  Question,
  SignOut,
  UserSquare,
} from "@phosphor-icons/react";
import { useRef, useEffect, useCallback } from "react";

import { useLightDismiss } from "@carescribe/ui";
import { getPlatform } from "@carescribe/utilities/src/browser";

import { helpSiteUrl } from "@talktype/config/src/helpSiteUrl";
import { Menu } from "@talktype/ui/src/Menu";

import { messages } from "./messages";
import { PreferencesModal } from "../PreferencesModal";

export type MainMenuProps = {
  preferencesFields: ReactNode;
  logOut: Void;
  dashboardUrl: string;
  canSubmitFeedback: boolean;
  submitFeedback: Void;
};

export const MainMenu = ({
  preferencesFields,
  logOut,
  canSubmitFeedback,
  submitFeedback,
  dashboardUrl,
}: MainMenuProps): ReactElement => {
  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = useCallback(
    (): void => modalRef.current?.showModal(),
    [modalRef]
  );

  const items: MenuItem[] = [
    {
      type: "button",
      id: "preferences",
      label: messages.preferences.label,
      Icon: Gear,
      shortcut: messages.preferences.shortcut,
      onClick: showModal,
    },
    {
      type: "link",
      id: "account",
      label: messages.account.label,
      Icon: UserSquare,
      href: dashboardUrl,
    },
    {
      type: "link",
      id: "helpCentre",
      label: messages.helpCentre.label,
      Icon: Question,
      href: helpSiteUrl,
    },
    ...(canSubmitFeedback
      ? [
          {
            type: "button" as const,
            id: "feedback",
            label: messages.feedback.label,
            Icon: ChatCenteredText,
            shortcut: null,
            onClick: submitFeedback,
          },
        ]
      : []),
    {
      type: "button",
      id: "logOut",
      label: messages.logOut.label,
      Icon: SignOut,
      shortcut: null,
      onClick: logOut,
    },
  ];

  // MacOS only: open preferences modal on Cmd + ,
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      const platform = getPlatform();

      if (platform === "mac" && event.metaKey && event.key === ",") {
        event.preventDefault();
        showModal();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [showModal]);

  return (
    <>
      <Menu items={items} />
      <PreferencesModal modalRef={modalRef}>
        {preferencesFields}
      </PreferencesModal>
    </>
  );
};
