import type { AppDispatch, RootState } from "@talktype/store";
import type { ChangeEventHandler } from "react";

import { connect } from "react-redux";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import {
  selectAlwaysOnTopEnabled,
  setAlwaysOnTop,
} from "@talktype/preferences";
import { PinTalkTypeToFrontField as Component } from "@talktype/ui/src/PreferencesModal/PinTalkTypeToFrontField";

type StateProps = { checked: boolean };
type DispatchProps = { onChange: ChangeEventHandler<HTMLInputElement> };

const mapStateToProps = (state: RootState): StateProps => ({
  checked: selectAlwaysOnTopEnabled(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChange: ({ currentTarget: { checked } }): void => {
    // TODO: Change state data structure to boolean (will require migration)
    dispatch(broadcast(setAlwaysOnTop(checked ? "always" : "never")));
  },
});

export const PinTalkTypeToFrontField = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
