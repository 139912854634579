import { messages as baseMessages } from "../../messages";
import { createMatchers } from "../../utils/createMatchers";
import { messages as commonMessages } from "../messages";

const { heading, off } = commonMessages;

export const messages = {
  matchers: createMatchers(heading, off),
  successful: "OK, heading is off!",
  failed: baseMessages.failureWithoutReason,
  listing: ["Heading off"],
};
