import type { Transcript } from "@talktype/types";

/**
 * Gets the final text character of a result's transcript.
 * Returns an empty string if no text segments are present.
 */
export const getFinalCharacterOfResult = ({
  transcript,
}: {
  transcript: Transcript;
}): string => {
  const lastTextSegment = transcript.at(-1);
  const isTextSegment = lastTextSegment?.type === "text";

  if (!isTextSegment) {
    return "";
  }

  return lastTextSegment.text.trim().at(-1) ?? "";
};
