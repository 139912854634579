import type { LineBreak } from "@talktype/types";

import { v4 as uuid } from "uuid";

import { lineBreakCharacter } from "@carescribe/slate";

import { defaultInlineStyleProperties } from "./entities/defaultInlineStyleProperties";

export const lineBreakNode = (
  marks?: Omit<Partial<LineBreak>, "text">
): LineBreak => ({
  ...defaultInlineStyleProperties,
  type: "linebreak" as const,
  text: lineBreakCharacter,
  id: uuid(),
  ...(marks ?? {}),
});
