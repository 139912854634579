import type { Platform } from "@carescribe/types/src/Platform";
import type { Message } from "@talktype/types/src/Message";

export const messages: {
  preferences: { label: string; shortcut: Message<{ platform: Platform }> };
} & Record<
  "account" | "helpCentre" | "logOut" | "feedback",
  { label: string }
> = {
  preferences: {
    label: "Preferences",
    shortcut: ({ platform }) => (platform === "mac" ? "⌘ + ," : ""),
  },
  account: { label: "Account" },
  helpCentre: { label: "Help centre" },
  feedback: { label: "Submit feedback" },
  logOut: { label: "Log out" },
};
