import type { AppDispatch } from "@talktype/store";
import type { ExitDictateAnywhereButtonProps } from "@talktype/ui/src/ExitDictateAnywhereButton";

import { connect } from "react-redux";

import { requestSetDictationMode } from "@talktype/editor";
import { ExitDictateAnywhereButton as Component } from "@talktype/ui/src/ExitDictateAnywhereButton";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<ExitDictateAnywhereButtonProps, "onClick"> => ({
  onClick: (event) =>
    dispatch(
      requestSetDictationMode({
        dictationMode: "talktype",
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),
});

export const ExitDictateAnywhereButton = connect(
  null,
  mapDispatchToProps
)(Component);
