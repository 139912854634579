export const suppressKnownWarnings = (): void => {
  const originalConsoleError = console.error;

  // TODO: both of these warnings should disappear when we upgrade from React 17
  const warningsToIgnore = [
    // https://github.com/facebook/react/issues/23301
    [
      "Warning: Invalid DOM property `%s`. Did you mean `%s`?%s",
      "autofocus",
      "autoFocus",
    ],
    //https://github.com/facebook/react/issues/21035
    [
      "Warning: Invalid aria prop %s on <%s> tag. For details, see https://reactjs.org/link/invalid-aria-props%s",
      "`aria-description`",
      "button",
    ],
  ];

  console.error = (...args: unknown[]): void => {
    if (typeof args[0] !== "string") {
      originalConsoleError(...args);
      return;
    }

    const isKnownWarning = warningsToIgnore.some((warning) =>
      warning.every((expectedArg, i) => args[i] === expectedArg)
    );

    if (isKnownWarning) {
      return;
    }

    originalConsoleError(...args);
  };
};
