import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

/**
 * Creates a resize event channel that emits whenever the dimensions of the body
 * element in the DOM change.
 */
export const createBodyResizeEventChannel = (): EventChannel<true> =>
  eventChannel<true>((emit) => {
    let previousDimensions = {
      width: document.body.offsetWidth,
      height: document.body.offsetHeight,
    };

    const resizeObserver = new ResizeObserver(() => {
      const dimensionsChanged =
        previousDimensions.width !== document.body.offsetWidth ||
        previousDimensions.height !== document.body.offsetHeight;

      /**
       * In theory we shouldn't need this as a resize observer should ONLY fire
       * whenever dimensions change. However, in practice, we've found that
       * when for instance, an Electron window is modified, it can trigger
       * false positives and we may end up in a loop.
       */
      if (!dimensionsChanged) {
        return;
      }

      previousDimensions = {
        width: document.body.offsetWidth,
        height: document.body.offsetHeight,
      };

      emit(dimensionsChanged);
    });

    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  });
