import type { AppMetadata } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, takeEvery, select } from "redux-saga/effects";

import { setActiveApp } from "@talktype/system/src/reducers/system";
import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";
import { getIpc } from "@talktype/utilities";
import { appIsEqual } from "@talktype/utilities/src/appIsEqual";

import { createActiveAppChangeChannel } from "./utils/createActiveAppChangeChannel";

/**
 * Listens to changes in the active app and dispatches the `setActiveApp`
 * action with the new active app.
 */
export const manageActiveAppTracking = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  if (!ipc) {
    return;
  }

  const activeAppEventChannel: SagaReturnType<
    typeof createActiveAppChangeChannel
  > = yield call(createActiveAppChangeChannel, ipc);

  yield takeEvery(activeAppEventChannel, function* (activeApp: AppMetadata) {
    const previousActiveApp: SagaReturnType<typeof selectActiveApp> =
      yield select(selectActiveApp);

    const isEqual: SagaReturnType<typeof appIsEqual> = yield call(
      appIsEqual,
      previousActiveApp,
      activeApp
    );

    if (isEqual) {
      return;
    }

    /**
     * Why override if `isSelf` is true?
     * In development mode, the app name is reported as "Electron"
     * instead of TalkType which can lead to buggy behaviour.
     */
    const newActiveApp = activeApp.isSelf
      ? { name: "TalkType", isSelf: true }
      : activeApp;

    yield put(setActiveApp(newActiveApp));
  });
};
