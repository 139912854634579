import type { SystemState } from "../../../types/SystemState";
import type { PayloadAction } from "@reduxjs/toolkit";

type SetPlatform = (
  state: SystemState,
  action: PayloadAction<SystemState["platform"]>
) => void;

export const setPlatform: SetPlatform = (state, { payload }) => {
  state.platform = payload;
};
