import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import {
  delay,
  call,
  put,
  select,
  takeEvery,
  debounce,
} from "redux-saga/effects";

import { createBodyResizeEventChannel } from "@carescribe/ui/src/sagas/createBodyResizeEventChannel";
import { createZoomEventChannel } from "@carescribe/ui/src/sagas/createZoomEventChannel";

import { setDictationMode } from "@talktype/editor";
import { setLoginStatus } from "@talktype/user/src/reducer";

import { requestLoadWindowLayout } from "./actions";
import { selectLayout } from "../reducers/system/selectors/selectLayout";

export const recalculateLayout = function* (): SagaIterator<void> {
  const layout: SagaReturnType<typeof selectLayout> = yield select(
    selectLayout
  );

  yield put(requestLoadWindowLayout(layout));
};

/**
 * Determines which window layout should be used based on the application state.
 */
export const manageWindowLayout = function* (): SagaIterator<void> {
  yield takeEvery([setLoginStatus, setDictationMode], recalculateLayout);

  const zoomEventChannel: SagaReturnType<typeof createZoomEventChannel> =
    yield call(createZoomEventChannel);
  yield takeEvery(zoomEventChannel, recalculateLayout);

  const bodyResizeEventChannel: SagaReturnType<
    typeof createBodyResizeEventChannel
  > = yield call(createBodyResizeEventChannel);

  /**
   * Stuff starts to go haywire if we don't do a bit of debouncing for the
   * body resize event channel.
   *
   * For example, resizing the browser quickly can cause the window to get into
   * a seizure like state of rapidly recalculating the layout and trying to
   * keep up with the resizes - not great.
   */
  yield debounce(100, bodyResizeEventChannel, recalculateLayout);

  /**
   * On the initial render, for unknown reasons the layout calculation can
   * sometimes fail to correctly adjust to the body's size. This can result
   * in responsive layouts being smaller than the DOM's body and content
   * being cut off.
   *
   * We therefore do a re-calculation after a short while to give enough time
   * for the layout to settle and re-confirm that the bounds are correct
   * relative to the body.
   */
  yield delay(200);
  yield call(recalculateLayout);
};
