import type { InlineStyleMarks, Leaf } from "@talktype/types";
import type { NodeEntry, EditorMarks } from "slate";

import { Editor, Range, Text } from "slate";

import { logError } from "../log";

const getInlineStyleMarks = (
  marks: Omit<EditorMarks, "type">
): InlineStyleMarks => {
  const { bold, italic, underline } = marks;
  return { bold, italic, underline };
};

/**
 * Merges new marks into an existing marks object.
 *
 * If the property already exists and is false, it will be kept as false.
 * Otherwise, the value from the current node will be used.
 */
const mergeMarks = (
  marks: InlineStyleMarks,
  key: keyof InlineStyleMarks,
  newMarks: InlineStyleMarks
): InlineStyleMarks => {
  // If the property already exists and is false, keep it as false
  if (marks[key] === false) {
    return marks;
  }
  // Otherwise, use the value from the current node
  return { ...marks, [key]: newMarks[key] };
};

/**
 * Consolidates the marks from a list of nodes.
 *
 * It retrieves the marks from each node and then merges these into a single
 * marks object.
 *
 * If a mark already exists in the accumulated marks, its value is updated
 * with the value from the current node unless it's explicitly set to false.
 **/
const consolidateMarks = (nodes: NodeEntry<Leaf>[]): InlineStyleMarks =>
  nodes.reduce((marks, [node]) => {
    const newMarks = getInlineStyleMarks(node);

    // Merge the new marks into the accumulated marks
    return Object.keys(newMarks).reduce(
      (acc, key) => mergeMarks(acc, key as keyof typeof newMarks, newMarks),
      marks
    );
  }, {} as InlineStyleMarks);

/**
 * Get the style marks that would be added to text at the current selection.
 *
 * Unlike, the native Editor.marks, this takes into consideration a situation
 * where the user has selected multiple text nodes with varying marks
 * and consolidates them rather than always using the first node's marks
 *
 * e.g. if some text is bolded and some isn't, this will return { bold: false }
 */
export const getInlineStyleSelectionMarks = (
  editor: Editor
): InlineStyleMarks | null => {
  try {
    const { selection } = editor;

    if (selection && Range.isExpanded(selection)) {
      const selectedTextNodes = Array.from(
        Editor.nodes(editor, { at: selection, match: Text.isText })
      );

      return consolidateMarks(selectedTextNodes);
    }

    const marks = Editor.marks(editor);

    return marks ? getInlineStyleMarks(marks) : null;
  } catch (cause) {
    logError(new Error("Failed to get inline style selection marks"), {
      cause,
    });
    return null;
  }
};
