import type { TranscriptTextSegment } from "@talktype/types";

import { defaultTextNodeProperties } from "./entities/defaultLeafProperties";

export const textTranscriptSegment = (
  input?: Partial<TranscriptTextSegment> | string
): TranscriptTextSegment => {
  const inputIsString = typeof input === "string";

  return {
    ...defaultTextNodeProperties,
    type: "text",
    text: inputIsString ? input : "",
    inProgress: true,
    onInsert: null,
    ...(!inputIsString && input ? input : {}),
  };
};
