import type { MoveSelectionInstruction } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { KeyPress } from "@talktype/types";

export const handleLine = async ({
  instruction: { direction },
  platform,
  pressKeys,
}: {
  instruction: MoveSelectionInstruction & { unit: "line" };
  platform: Extract<Platform, "win" | "mac">;
  pressKeys: (keypresses: KeyPress[]) => Promise<boolean>;
}): Promise<boolean> => {
  const macKey = direction === "end" ? "Right" : "Left";
  const winKey = direction === "end" ? "End" : "Home";

  const keypressPerPlatform: Record<"mac" | "win", KeyPress> = {
    mac: { key: macKey, metaKey: true },
    win: { key: winKey },
  };

  const keypresses: KeyPress[] = [keypressPerPlatform[platform]];

  return pressKeys(keypresses);
};
