import type { DictationStatus } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, select, call } from "redux-saga/effects";

import { preventDefault } from "@carescribe/utilities/src/sagas";

import {
  requestStopDictating,
  requestToggleDictation,
} from "@talktype/actions";
import { requestTrackEvent } from "@talktype/analytics";
import { startDictating } from "@talktype/editor";
import { selectDictationStatus } from "@talktype/editor/src/reducers";
import { getEditorIsFocused } from "@talktype/editor/src/sagas/utils";

export const manageDictationButton = function* (): SagaIterator {
  yield takeEvery(
    requestToggleDictation,
    function* ({ payload: { event, interactionMethod } }) {
      const editorIsFocused: SagaReturnType<typeof getEditorIsFocused> =
        yield call(getEditorIsFocused);

      /**
       * If the editor is focused, the virtual keyboard is likely open on
       * touch devices. In this case, we prevent the default action to stop
       * the dictation button from taking focus. This ensures that clicking
       * the dictation button does not change the state of the virtual
       * keyboard (open/close) and only toggles dictation.
       */
      if (editorIsFocused && event) {
        yield call(preventDefault, event);
      }

      const dictationStatus: SagaReturnType<typeof selectDictationStatus> =
        yield select(selectDictationStatus);

      switch (dictationStatus) {
        case "inactive":
          yield put(startDictating());
          break;
        case "loading":
        case "active":
          yield put(requestStopDictating());
      }

      const newDictationStatus: Record<DictationStatus, "on" | "off"> = {
        inactive: "on",
        loading: "off",
        active: "off",
      };

      yield put(
        requestTrackEvent({
          name: "Dictation Toggled",
          data: {
            newDictationStatus: newDictationStatus[dictationStatus],
            interactionMethod,
          },
        })
      );
    }
  );
};
