import type { Toast } from "@talktype/types";
import type { ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { ToastsContainer } from "@talktype/toasts/src/components/ToastsContainer";

import { messages } from "./messages";
import styles from "./notifications.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type NotificationsProps = {
  toasts: Toast[];
  renderToast: (toast: Toast) => ReactElement;
};

export const Notifications = ({
  toasts,
  renderToast,
}: NotificationsProps): ReactElement =>
  toasts.length > 0 ? (
    <aside
      className={classNames(
        styles.container,
        createSelectorClassName("notifications", "section")
      )}
      aria-labelledby="notifications-title"
    >
      <ScreenReaderOnly id="notifications-title" tag="h2">
        {messages.notifications}
      </ScreenReaderOnly>

      <ToastsContainer toasts={toasts} renderToast={renderToast} />
    </aside>
  ) : (
    <></>
  );
