import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";
import { ReactEditor } from "slate-react";

import { getEditor } from "@talktype/editor/src/sagas/utils";

export const getEditorIsFocused = function* (): SagaIterator<boolean> {
  const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

  if (!editor) {
    return false;
  }

  const editorIsFocused: SagaReturnType<typeof ReactEditor.isFocused> =
    yield call(ReactEditor.isFocused, editor);

  return editorIsFocused;
};
