import type {
  ReactElement,
  ComponentType,
  MouseEventHandler,
  KeyboardEventHandler,
} from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./stackedDropdownMenuRow.module.scss";
import { IconContainer } from "../IconContainer";

export type StackedDropdownMenuRowProps = {
  title: string;
  description: string;
  Icon: ComponentType;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onKeyDown: KeyboardEventHandler<HTMLButtonElement>;
};

export const StackedDropdownMenuRow = ({
  title,
  description,
  Icon,
  onClick,
  onKeyDown,
}: StackedDropdownMenuRowProps): ReactElement => (
  <button
    className={classNames(
      styles.button,
      createSelectorClassName("dropdown-menu-row", "section")
    )}
    onClick={onClick}
    onKeyDown={onKeyDown}
  >
    <span className={styles.title}>
      <IconContainer className={styles.icon}>
        <Icon />
      </IconContainer>
      {title}
    </span>
    <span className={styles.description}>{description}</span>
  </button>
);
