import type { InlineStyleMarks } from "@talktype/types";

import { pick } from "@carescribe/utilities/src/fp";

import { InlineStyles } from "@talktype/types";

/**
 * Given a marks object, extracts the inline styles.
 *
 * @example
 * getInlineStyle({
 *  inProgress: true,
 *  bold: false,
 *  italic: false,
 *  underline: false
 * }); // { bold: false, italic: false, underline: false }
 */
export const getInlineStyle = (
  marks: InlineStyleMarks & Record<string, unknown>
): InlineStyleMarks => pick(InlineStyles, marks);
