import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { isText, zeroWidthSpace } from "@carescribe/slate";

import { editorLoaded } from "../actions";

/**
 * Remove Zero Width Spaces from Populated Text
 *
 * Slate often inserts \uFEFF (zero width spaces) when other nodes are inserted.
 * Once normalised, these can interfere with keyboard cursor navigation and
 * should be removed if they exist with other text.
 */
export const removeZeroWidthSpacesFromPopulatedText =
  function* (): SagaIterator<void> {
    yield takeEvery(editorLoaded, ({ payload: editor }) => {
      const { normalizeNode } = editor;

      editor.normalizeNode = ([node, path]): void => {
        if (isText(node) && node.text.includes(zeroWidthSpace)) {
          const { selection } = editor;

          const newText = node.text.replaceAll(zeroWidthSpace, "");
          editor.insertText(newText, { at: path });

          if (selection) {
            editor.select(selection);
          }
        }

        normalizeNode([node, path]);
      };
    });
  };
