import type {
  Chunk,
  AssignedResult,
  Result,
  TranscriptTransformationSettings,
  InitialResult,
} from "@talktype/types";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/results");

export const requestBroadcastInProgressResult = action<
  void,
  "request-broadcast-in-progress-result"
>("request-broadcast-in-progress-result");

export const broadcastedInProgressResult = action<
  AssignedResult | null,
  "broadcasted-in-progress-result"
>("broadcasted-in-progress-result");

export const requestFinaliseInProgressResult = action<
  void,
  "request-finalise-in-progress-result"
>("request-finalise-in-progress-result");

export const finalisedInProgressResult = action<
  void,
  "finalised-in-progress-result"
>("finalised-in-progress-result");

export const requestAssignResult = action<
  InitialResult,
  "request-assign-result"
>("request-assign-result");

export const assignedResult = action<AssignedResult, "assigned-result">(
  "assigned-result"
);

export const requestCategoriseResult = action<
  AssignedResult,
  "request-categorise-result"
>("request-categorise-result");

export const categorisedResult = action<
  { isStale: boolean; isNew: boolean },
  "categorised-result"
>("categorised-result");

export const requestReshapeResult = action<
  AssignedResult,
  "request-reshape-result"
>("request-reshape-result");

export const reshapedResult = action<Result, "reshaped-result">(
  "reshaped-result"
);

export const requestAssembleTranscript = action<
  Result,
  "request-assemble-transcript"
>("request-assemble-transcript");

export const assembledTranscript = action<Result, "assembled-transcript">(
  "assembled-transcript"
);

export const requestApplyTransformations = action<
  Result,
  "request-apply-transformations"
>("request-apply-transformations");

export const appliedTransformations = action<Result, "applied-transformations">(
  "applied-transformations"
);

export const requestApplyVoiceCommands = action<
  Result,
  "request-apply-voice-commands"
>("request-apply-voice-commands");

export const appliedVoiceCommands = action<Result, "applied-voice-commands">(
  "applied-voice-commands"
);

export const requestTransformTranscript = action<
  {
    result: Result;
    precedingChunk: Chunk;
    settings: TranscriptTransformationSettings;
  },
  "request-transform-transcript"
>("request-transform-transcript");

export const transformedTranscript = action<
  {
    result: Result;
    precedingChunk: Chunk;
    settings: TranscriptTransformationSettings;
  },
  "transformed-transcript"
>("transformed-transcript");

export const requestSendResult = action<Result, "request-send-result">(
  "request-send-result"
);

export const requestAddResultToEditor = action<
  Result,
  "request-add-result-to-editor"
>("request-add-result-to-editor");

export const requestSendResultToDesktopApp = action<
  Result,
  "request-add-result-to-app"
>("request-add-result-to-app");

export const resultSent = action<Result, "result-sent">("result-sent");
