import type { BaseRange } from "slate";

import { Editor, Text, Range } from "slate";

import { logError } from "../log";

/**
 * Gets the text of the previous leaf node within the same block.
 */
export const getPreviousLeafText = (editor: Editor, at: BaseRange): string => {
  try {
    const pointBeforeLocation = Editor.before(editor, at);

    if (!pointBeforeLocation) {
      return "";
    }

    const [block] = Editor.parent(editor, at);

    if (Editor.isEditor(block)) {
      return "";
    }

    const [previousLeafEntry] = Editor.nodes(editor, {
      reverse: true,
      at: pointBeforeLocation,
      match: (n) => Text.isText(n) && n.text.trim() !== "",
    });

    if (!previousLeafEntry) {
      return "";
    }

    const [previousLeaf, previousLeafPath] = previousLeafEntry;

    if (!Text.isText(previousLeaf)) {
      return "";
    }

    // Ensure the previous leaf is within the same block
    const [previousLeafBlock] = Editor.parent(editor, previousLeafPath);
    if (previousLeafBlock !== block) {
      return "";
    }

    const previousLeafRange = {
      anchor: { path: pointBeforeLocation.path, offset: 0 },
      focus: { path: previousLeafPath, offset: previousLeaf.text.length },
    };

    // Adjust the text to account for the selection offset
    const selectionInterceptionWithPreviousLeaf = Range.intersection(
      at,
      previousLeafRange
    );

    if (selectionInterceptionWithPreviousLeaf) {
      return previousLeaf.text.slice(
        0,
        selectionInterceptionWithPreviousLeaf.anchor.offset
      );
    }
    return previousLeaf.text;
  } catch (cause) {
    logError(new Error("Failed to get previous leaf text"), { cause });
    return "";
  }
};
