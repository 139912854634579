import type { RootState } from "@talktype/store";
import type { LayoutProps } from "@talktype/ui/src/Layout";

import { connect } from "react-redux";

import { selectLayout } from "@talktype/system/src/reducers/system/selectors/selectLayout";
import { Layout as Component } from "@talktype/ui/src/Layout";

import { CompactLayout } from "./CompactLayout";
import { RegularLayout } from "./RegularLayout";

const mapStateToProps = (state: RootState): LayoutProps => {
  return {
    layout: selectLayout(state),
    regularLayout: <RegularLayout />,
    compactLayout: <CompactLayout />,
  };
};

export const Layout = connect(mapStateToProps)(Component);
