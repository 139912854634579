import type { RefObject } from "react";

/**
 * @TODO: Remove after upgrading TypeScript
 *
 * Your IDE might be fine without these, but the types check script will fail
 */
declare global {
  interface HTMLElement {
    hidePopover(): void;
    showPopover(): void;
  }
}

export const showPopover = (tooltipRef: RefObject<HTMLElement>): void => {
  try {
    tooltipRef.current?.showPopover();
  } catch (error) {
    console.warn("Error showing tooltip", error);
  }
};

export const hidePopover = (tooltipRef: RefObject<HTMLElement>): void => {
  try {
    tooltipRef.current?.hidePopover();
  } catch (error) {
    console.warn("Error hiding tooltip", error);
  }
};
