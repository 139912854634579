import type { CombinedState, FeatureFlags } from ".";

import { createSelector } from "@reduxjs/toolkit";

import { selectParsedAppVersion } from "@talktype/system/src/reducers/system/selectors/selectParsedAppVersion";

export const selectFeatureFlags = (state: CombinedState): FeatureFlags =>
  state.featureFlags;

export const selectDictateToAppIsEnabled = createSelector(
  selectFeatureFlags,
  (state) => state.dictateToApp
);

export const selectChameleonIsEnabled = createSelector(
  selectFeatureFlags,
  (state) => state.chameleon
);

export const selectCompactLayoutIsEnabled = createSelector(
  selectParsedAppVersion,
  (appVersion) =>
    appVersion ? appVersion.major >= 3 && appVersion.minor >= 3 : false
);

export const selectLibrarySystemIsEnabled = createSelector(
  selectFeatureFlags,
  (state) => state.librarySystem
);
