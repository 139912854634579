import type { WindowLayout } from "../types/WindowLayout";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { StrictMap } from "@carescribe/utilities/src/StrictMap";

import { compactWindowLayout } from "@talktype/config/src/compactWindowLayout";
import { regularWindowLayout } from "@talktype/config/src/regularWindowLayout";
import { requestLoadWindowLayout } from "@talktype/system/src/sagas/actions";
import { getIpc } from "@talktype/utilities";

export const getZoomLevel = (): number => window.outerWidth / window.innerWidth;

const calculateBounds = function* ({
  layout,
  bounds,
  isRecalculation,
  minSize,
  maxSize,
}: {
  layout: WindowLayout;
  bounds: Required<WindowLayout["bounds"]>;
  isRecalculation: boolean;
  minSize: Required<WindowLayout["minSize"]>;
  maxSize: Required<WindowLayout["maxSize"]>;
}): SagaIterator<WindowLayout["bounds"]> {
  const newBounds = { ...bounds, ...layout.bounds };

  /*
   * Order of the below mutations is important otherwise stuff breaks!
   * For example: scaling must be done after responsiveness otherwise it won't
   * account for it, leading to UI getting cut off while zoomed in.
   */

  /**
   * Document body's size is ONLY taken into account when:
   * - Layout is responsive
   * - Layout is being re-calculated (same layout)
   *   This second condition may seem entirely redundant at first glance. When
   *   switching between layouts, taking the body's size into account while the
   *   previous layout is still active can lead to inaccurate, larger than
   *   expected bounds. It is quite rare in the case of switching between the
   *   regular and compact layout for instance, but it does happen!
   */
  if (layout.responsive && isRecalculation) {
    newBounds.height = Math.max(newBounds.height, document.body.offsetHeight);
    newBounds.width = Math.max(newBounds.width, document.body.offsetWidth);
  }

  if (layout.scalesWithZoom) {
    const zoomLevel: SagaReturnType<typeof getZoomLevel> = yield call(
      getZoomLevel
    );
    newBounds.width = newBounds.width * zoomLevel;
    newBounds.height = newBounds.height * zoomLevel;
  }

  /**
   * When bounds change during re-calculation (same layout), anchoring is
   * applied to minimise the layout shift
   */
  if (isRecalculation) {
    newBounds.x = newBounds.x + (bounds.width - newBounds.width) / 2;
    newBounds.y = newBounds.y + (bounds.height - newBounds.height) / 2;
  }

  // Ensure new bounds are at least the minimum size
  newBounds.width = Math.max(newBounds.width, minSize.width);
  newBounds.height = Math.max(newBounds.height, minSize.height);

  // Ensure new bounds do not exceed max size
  newBounds.width = Math.min(newBounds.width, maxSize.width);
  newBounds.height = Math.min(newBounds.height, maxSize.height);

  // Bounds MUST be integers. Rounding is important otherwise stuff breaks!
  newBounds.x = Math.round(newBounds.x);
  newBounds.y = Math.round(newBounds.y);
  newBounds.width = Math.round(newBounds.width);
  newBounds.height = Math.round(newBounds.height);

  return newBounds;
};

const updateLayoutState = function* ({
  layouts,
  layoutId,
  bounds,
}: {
  layouts: StrictMap<WindowLayout["id"], WindowLayout>;
  layoutId: WindowLayout["id"] | null;
  bounds: Required<WindowLayout["bounds"]>;
}): SagaIterator<void> {
  if (layoutId === null) {
    return;
  }

  const layout = layouts.getStrict(layoutId);

  const shouldUpdate = layout && layout.resizable;
  if (!shouldUpdate) {
    return;
  }

  const { height, width } = bounds;
  const newBounds = { ...layout.bounds, height, width };

  layouts.set(layout.id, { ...layout, bounds: newBounds });
};

/**
 * Manages the window layout changes.
 * e.g. going from the regular layout to the compact layout.
 *
 * There's quite a bit that goes into calculating the new bounds when
 * transitioning between layouts:
 *
 * - We start off with the initial bounds which is a combination of the layout's
 *   initial bounds and the window's current bounds
 * - When transitioning away from a resizable window layout, its current size is
 *   stored in state so that it can be later restored when going back to it
 * - Scaling bounds by zoom level for layouts that require it
 * - Adjusting bounds to the body size for responsive layouts
 * - Anchoring bounds to the current window position if the same layout is being
 *   re-applied
 */
export const manageWindowLayoutChanges = function* (): SagaIterator<void> {
  const layouts = new StrictMap<WindowLayout["id"], WindowLayout>([
    ["compact", compactWindowLayout],
    ["regular", regularWindowLayout],
  ]);
  let currentLayoutId: WindowLayout["id"] | null = null;

  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  if (!ipc) {
    return;
  }

  const modify = ipc.system?.window?.modify?.v1;
  const getBounds = ipc.system?.window?.getBounds?.v1;

  if (!modify || !getBounds) {
    return;
  }

  yield takeEvery(requestLoadWindowLayout, function* ({ payload: layoutId }) {
    const currentBounds: SagaReturnType<typeof getBounds> = yield call(
      getBounds
    );

    yield call(updateLayoutState, {
      layouts,
      layoutId: currentLayoutId,
      bounds: currentBounds,
    });

    const layout = layouts.getStrict(layoutId);
    const isRecalculation = currentLayoutId === layoutId;
    const {
      minSize,
      maxSize,
      resizable,
      trafficLightsVisible,
      maximisable,
      fullScreenable,
    } = layout;

    const bounds: SagaReturnType<typeof calculateBounds> = yield call(
      calculateBounds,
      { layout, bounds: currentBounds, isRecalculation, minSize, maxSize }
    );

    yield call(modify, {
      animate: false,
      bounds,
      fullScreenable,
      maximisable,
      minSize,
      maxSize,
      resizable,
      trafficLightsVisible,
    });

    currentLayoutId = layoutId;
  });
};
