import type { RootState, AppDispatch } from "@talktype/store";
import type { DictateAnywhereCloseWindowButtonProps } from "@talktype/ui/src/DictateAnywhereCloseWindowButton";

import { connect } from "react-redux";

import { selectPlatform } from "@talktype/system/src/reducers/system/selectors/selectPlatform";
import { pressedWindowControlButton } from "@talktype/system/src/sagas/actions";
import { DictateAnywhereCloseWindowButton as Component } from "@talktype/ui/src/DictateAnywhereCloseWindowButton";

const mapStateToProps = (
  state: RootState
): Pick<DictateAnywhereCloseWindowButtonProps, "platform"> => ({
  platform: selectPlatform(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<DictateAnywhereCloseWindowButtonProps, "onClose"> => ({
  onClose: () => dispatch(pressedWindowControlButton("close")),
});

export const DictateAnywhereCloseWindowButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
