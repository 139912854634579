import type { CheckboxSliderProps } from "../../CheckboxSlider";
import type { ReactElement } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import { CheckboxSlider } from "../../CheckboxSlider";
import { Field } from "../../Field";

export type PinTalkTypeToFrontFieldProps = CheckboxSliderProps["checkboxProps"];

export const PinTalkTypeToFrontField = (
  checkboxProps: PinTalkTypeToFrontFieldProps
): ReactElement => (
  <Field
    label={{ text: messages.label, visible: true }}
    explainer={{ text: messages.explainer }}
    error={null}
    className={createSelectorClassName(
      "pin-talktype-to-front-field",
      "interactive"
    )}
  >
    <CheckboxSlider checkboxProps={checkboxProps} size="sm" />
  </Field>
);
