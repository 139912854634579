import type { WindowLayout } from "@talktype/system/src/types/WindowLayout";

export const regularWindowLayout: WindowLayout = {
  id: "regular",
  bounds: {},
  minSize: { width: 500, height: 350 },
  maxSize: { width: 99999, height: 99999 }, // No limit in practice
  resizable: true,
  scalesWithZoom: false,
  responsive: false,
  trafficLightsVisible: true,
  maximisable: true,
  fullScreenable: true,
};
