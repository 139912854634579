import type { CommandCreator } from "../../../types";
import type { Block } from "@talktype/types";

import { put, take } from "redux-saga/effects";

import { editorBlockSet, requestSetBlock } from "@talktype/editor";

export const setBlock: CommandCreator<Block> = (block) =>
  function* () {
    yield put(requestSetBlock(block));
    yield take(editorBlockSet);

    return true;
  };
