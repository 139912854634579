import type { CommandCreator } from "../../../types";
import type { InlineStyles } from "@talktype/types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  requestSetInlineStyle,
  inlineStyleSet,
} from "@talktype/editor/src/sagas/actions";

export const setInlineStyle: CommandCreator<[InlineStyles, boolean]> = (
  style
) =>
  function* () {
    yield put(requestSetInlineStyle(style));
    const { payload: success }: SagaReturnType<typeof inlineStyleSet> =
      yield take(inlineStyleSet);

    return success;
  };
