import type { Path, Point, Range } from "slate";

export const zeroWidthSpace = "\uFEFF";

export const lineBreakCharacter = "\n";
export const newLineRegex = new RegExp(`(${lineBreakCharacter})`);

export const emptyPath: Path = [0, 0];

export const emptyPoint: Point = { offset: 0, path: emptyPath };

export const emptyRange: Range = { anchor: emptyPoint, focus: emptyPoint };
