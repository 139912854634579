import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import { stopDictating } from "@talktype/editor";
import { resultSent } from "@talktype/results/src/sagas/actions";
import { getFinalCharacterOfResult } from "@talktype/utilities";

import {
  broadcastDictateToAppPrecedingText,
  requestDictateToAppPrecedingText,
} from "./actions";

/**
 * Manage Dictate-to-App Preceding Text
 *
 * Keeps track of the final character of the previously completed result. The
 * Dictate to App feature in Electron has no way of reporting what the
 * character to the left of the user's cursor is, so we use this to make the
 * best guess of what is probably there based on the last result to be inserted
 */
export const manageDictateToAppPrecedingText =
  function* (): SagaIterator<void> {
    // Set up some state to keep track of the previous result's text
    // content for the purposes of performing stateful grammar in
    // dictate-to-app mode.
    const previousLastCharacterPerActiveApp = new Map<string, string>();

    yield takeEvery(stopDictating, function* () {
      previousLastCharacterPerActiveApp.clear();
    });

    yield takeEvery(
      requestDictateToAppPrecedingText,
      function* ({ payload: { targetApp } }) {
        const previousText =
          previousLastCharacterPerActiveApp.get(targetApp.name) ?? "";
        yield put(broadcastDictateToAppPrecedingText(previousText));
      }
    );

    yield takeEvery(resultSent, function* ({ payload: result }) {
      if (result.targetApp.isSelf) {
        return;
      }

      if (!result.isFinal) {
        return;
      }

      const newLastCharacter = getFinalCharacterOfResult(result);

      previousLastCharacterPerActiveApp.set(
        result.targetApp.name,
        newLastCharacter
      );
    });
  };
