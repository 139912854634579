import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { lineBreakCharacter } from "@carescribe/slate";

import { isLineBreak } from "../../guards/isLineBreak";
import { editorLoaded } from "../actions";

/**
 * Convert Line Breaks to Text
 *
 * If a line break doesn't contain a new line character, it should be
 * converted to text.
 */
export const convertLineBreaksToText = function* (): SagaIterator<void> {
  yield takeEvery(editorLoaded, ({ payload: editor }) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]): void => {
      if (isLineBreak(node) && !node.text.includes(lineBreakCharacter)) {
        editor.setNodes({ type: "text", inProgress: false }, { at: path });
      }

      normalizeNode([node, path]);
    };
  });
};
