import { createSelector } from "@reduxjs/toolkit";

import { selectDictationMode } from "@talktype/editor/src/reducers";
import { selectAlwaysOnTopEnabled } from "@talktype/preferences/src/reducers/preferences";
import { selectUserIsLoggedIn } from "@talktype/user/src/reducer/selectors/selectUserIsLoggedIn";

export const selectPinToFront = createSelector(
  selectUserIsLoggedIn,
  selectDictationMode,
  selectAlwaysOnTopEnabled,
  (userIsLoggedIn, dictationMode, alwaysOnTopEnabled) =>
    userIsLoggedIn && (dictationMode === "anywhere" || alwaysOnTopEnabled)
);
