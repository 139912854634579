import type { Leaf } from "@talktype/types";
import type { Descendant } from "slate";

const finaliseLeaf = (leaf: Leaf): Leaf =>
  "inProgress" in leaf ? { ...leaf, inProgress: false } : leaf;

/**
 * Traverses a tree of Slate nodes and sets the `inProgress` property to `false`
 * for any leaf nodes that have it.
 */
export const finaliseChildren = (children: Descendant[]): Descendant[] =>
  children.map((child) => {
    if ("children" in child) {
      const children = child.children.map(finaliseLeaf);
      return { ...child, children };
    }

    return finaliseLeaf(child);
  });
