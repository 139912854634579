import type { selectDictationMode } from "@talktype/editor";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, takeEvery, select } from "redux-saga/effects";

import { stopDictating } from "@talktype/editor";
import { setActiveApp } from "@talktype/system/src/reducers/system";
import { addToast, dismissToast } from "@talktype/toasts/src/sagas/actions";

import { selectShouldShowDictatingToToast } from "../selectors";

const DICTATING_TO_TOAST_ID = "dictating-to";

/**
 * Whenever the active app changes, displays the "dictating to {app}" toast
 * notification. Dismisses it if it should not be shown or if dictation stops.
 */
export const manageNotifications = function* (): SagaIterator<void> {
  yield takeEvery(setActiveApp, function* ({ payload: activeApp }) {
    const shouldShowDictationToast: SagaReturnType<typeof selectDictationMode> =
      yield select(selectShouldShowDictatingToToast);

    if (!shouldShowDictationToast) {
      yield put(dismissToast("dictating-to"));
      return;
    }

    yield put(
      addToast({
        id: DICTATING_TO_TOAST_ID,
        type: "dictation",
        status: "dictating-to",
        text: activeApp.name,
        order: 999, // Always last
        dismissConfig: { after: null, notBefore: null },
      })
    );
  });

  yield takeEvery(stopDictating, function* () {
    yield put(dismissToast("dictating-to"));
  });
};
