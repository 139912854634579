import type { MoveSelectionInstruction } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { KeyPress } from "@talktype/types";

import { match } from "ts-pattern";

import { handleCharacter } from "./handleCharacter";
import { handleDocument } from "./handleDocument";
import { handleLine } from "./handleLine";
import { handleParagraph } from "./handleParagraph";
import { handleWord } from "./handleWord";

/**
 * Move the selection within the focused app based on the given instruction.
 *
 * @param instruction - The instruction detailing how to move the selection.
 * @param platform - The platform on which the app is running (win or mac).
 * @param pressKeys - Function to simulate key presses.
 *
 * @example
 * // Move the selection one character forward
 * moveSelection({
 *   instruction: { unit: "character", direction: "forward", distance: 1 },
 *   platform: "win",
 *   pressKeys: (hotkeys) => {}
 * });
 *
 * // Move the selection two words backward
 * moveSelection({
 *   instruction: { unit: "word", direction: "back", distance: 2 },
 *   platform: "mac",
 *   pressKeys: (hotkeys) => {}
 * });
 *
 * // Move the selection to the next paragraph
 * moveSelection({
 *   instruction: { unit: "paragraph", direction: "forward", distance: 1 },
 *   platform: "win",
 *   pressKeys: (hotkeys) => {}
 * });
 *
 * // Move the selection to the start of the current line
 * moveSelection({
 *   instruction: { unit: "line", direction: "start", distance: null },
 *   platform: "mac",
 *   pressKeys: (hotkeys) => {}
 * });
 *
 * // Move the selection to the end of the document
 * moveSelection({
 *   instruction: { unit: "document", direction: "end", distance: null },
 *   platform: "win",
 *   pressKeys: (hotkeys) => {}
 * });
 */
export const moveSelection = async ({
  instruction,
  platform,
  pressKeys,
}: {
  instruction: MoveSelectionInstruction;
  platform: Extract<Platform, "win" | "mac">;
  pressKeys: (keypresses: KeyPress[]) => Promise<boolean>;
}): Promise<boolean> =>
  match({ instruction, platform, pressKeys })
    .with({ instruction: { unit: "character" } }, handleCharacter)
    .with({ instruction: { unit: "word" } }, handleWord)
    .with({ instruction: { unit: "line" } }, handleLine)
    .with({ instruction: { unit: "paragraph" } }, handleParagraph)
    .with({ instruction: { unit: "document" } }, handleDocument)
    .exhaustive();
