import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";
import { moveSelection } from "@carescribe/slate/src/utils/moveSelection";

import { requestMoveSelection, selectionMoved } from "..";
import { logError } from "../../utils/log";
import { getEditor } from "../utils";

/**
 * Handle requests to move the selection within the editor based on the given
 * instruction.
 */
export const manageMoveSelection = function* (): SagaIterator<void> {
  yield takeEvery(requestMoveSelection, function* ({ payload: instruction }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(selectionMoved({ success: false }));
      return;
    }

    yield call(focusEditor, editor);

    try {
      yield call(moveSelection, editor, instruction);
    } catch (error) {
      yield call(
        logError,
        "Unable to move selection:",
        error instanceof Error ? error.message : JSON.stringify(error)
      );
      yield put(selectionMoved({ success: false }));
      return;
    }

    yield put(selectionMoved({ success: true }));
  });
};
