import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { audioConnected } from "@carescribe/audio/src/sagas/actions";
import { putSaga } from "@carescribe/utilities/src/sagas";

import { dictationStarted } from "@talktype/editor/src/reducers";

/**
 * Lets the editor know when dictation is ready.
 */
export const manageDictationStatus = function* (): SagaIterator<void> {
  yield takeEvery(audioConnected, putSaga(dictationStarted()));
};
