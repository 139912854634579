import type { EditorType } from "../editor";
import type { MoveSelectionInstruction } from "@carescribe/types";
import type { InteractionMethod } from "@talktype/analytics";
import type {
  InlineStyleMarks,
  InlineStyles,
  NonStylingButtonNames,
  AppTargetType,
  Block,
  DictationMode,
} from "@talktype/types";
import type { KeyboardEvent, MouseEvent, ClipboardEvent } from "react";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-editor");

export const editorLoaded = action<EditorType, "loaded">("loaded");

export const editorRestored = action<EditorType, "restored">("restored");

export const editorUnloaded = action<void, "unloaded">("unloaded");

export const requestEditor = action<void, "request-editor">("request-editor");

export const broadcastEditor = action<EditorType | null, "broadcast-editor">(
  "broadcast-editor"
);

export const editorChanged = action<EditorType["operations"], "changed">(
  "changed"
);

export const editorErrored = action<unknown, "errored">("errored");

export const editorClicked = action<MouseEvent, "clicked">("clicked");

export const editorKeyUp = action<KeyboardEvent, "keyup">("keyup");

export const editorCut = action<ClipboardEvent, "cut">("cut");

export const editorCopy = action<ClipboardEvent, "copy">("copy");

export const editorPaste = action<ClipboardEvent, "paste">("paste");

export const startDictating = action<void, "start-dictating">(
  "start-dictating"
);

export const editorKeyDown = action<KeyboardEvent, "key-down">("key-down");

export const requestToggleInlineStyle = action<
  InlineStyles,
  "request-toggle-inline-style"
>("request-toggle-inline-style");

export const requestBroadcastInlineStyles = action<
  void,
  "request-broadcast-inline-styles"
>("request-broadcast-inline-styles");

export const broadcastedInlineStyles = action<
  InlineStyleMarks,
  "broadcasted-inline-styles"
>("broadcasted-inline-styles");

export const requestPressEnter = action<
  { target: AppTargetType },
  "request-press-enter"
>("request-press-enter");

export const pressedEnter = action<boolean, "pressed-enter">("pressed-enter");

export const requestSetInlineStyle = action<
  [InlineStyles, boolean],
  "set-inline-style"
>("set-inline-style");

export const inlineStyleSet = action<boolean, "inline-style-set">(
  "inline-style-set"
);

export const requestSelectPhrase = action<string, "request-select-phrase">(
  "request-select-phrase"
);

export const selectedPhrase = action<boolean, "selected-phrase">(
  "selected-phrase"
);

export const requestSelectRange = action<
  { from: string; to: string },
  "request-select-range"
>("request-select-range");

export const selectedRange = action<boolean, "selected-range">(
  "selected-range"
);

export const editorToggledMark = action<boolean, "editor-toggled-mark">(
  "editor-toggled-mark"
);

export const requestToggleBlock = action<Block, "request-toggle-block">(
  "request-toggle-block"
);

export const editorBlockToggled = action<boolean, "editor-block-toggled">(
  "editor-block-toggled"
);

export const requestSetBlock = action<Block, "request-set-block">(
  "request-set-block"
);

export const editorBlockSet = action<void, "editor-block-set">(
  "editor-block-set"
);

export const editorFormattingBarInteracted = action<
  {
    name: InlineStyles | Exclude<Block, "paragraph"> | NonStylingButtonNames;
    event: KeyboardEvent | MouseEvent;
  },
  "editor-formatting-bar-interacted"
>("editor-formatting-bar-interacted");

export const requestHistory = action<"undo" | "redo", "request-history">(
  "request-history"
);

export const historyUpdated = action<boolean, "history-updated">(
  "history-updated"
);

export const requestClearEditorContents = action<
  void,
  "request-clear-editor-contents"
>("request-clear-editor-contents");

export const clearedEditorContents = action<boolean, "cleared-editor-contents">(
  "cleared-editor-contents"
);

export const requestCopyEditorContents = action<
  void,
  "request-copy-editor-contents"
>("request-copy-editor-contents");

export const editorContentsCopied = action<
  { characterCount: number },
  "editor-contents-copied"
>("editor-contents-copied");

export const requestDisableSaving = action<void, "request-disable-saving">(
  "request-disable-saving"
);

export const requestEnableSaving = action<void, "request-enable-saving">(
  "request-enable-saving"
);

export const savingDisabled = action<void, "saving-disabled">(
  "saving-disabled"
);

export const savingEnabled = action<void, "saving-enabled">("saving-enabled");

export const requestDeleteSelection = action<void, "request-delete-selection">(
  "request-delete-selection"
);

export const selectionDeleted = action<boolean, "selection-deleted">(
  "selection-deleted"
);

export const requestDeletePhrase = action<string, "request-delete-phrase">(
  "request-delete-phrase"
);

export const deletedPhrase = action<boolean, "deleted-phrase">(
  "deleted-phrase"
);

export const requestExecuteBackspace = action<
  void,
  "request-execute-backspace"
>("request-execute-backspace");

export const executedBackspace = action<void, "executed-backspace">(
  "executed-backspace"
);

export const requestDeleteRange = action<
  { from: string; to: string },
  "request-delete-range"
>("request-delete-range");

export const deletedRange = action<boolean, "deleted-range">("deleted-range");

export const requestCursorMove = action<
  "up" | "down" | "left" | "right",
  "request-cursor-move"
>("request-cursor-move");

export const movedCursor = action<boolean, "moved-cursor">("moved-cursor");

export const requestMoveSelection = action<
  MoveSelectionInstruction,
  "request-move-selection"
>("request-move-selection");

export const selectionMoved = action<{ success: boolean }, "selection-moved">(
  "selection-moved"
);

export const requestClearInProgressContent = action<
  { resultId: string },
  "request-clear-in-progress-content"
>("request-clear-in-progress-content");

export const clearedInProgressContent = action<
  void,
  "cleared-in-progress-content"
>("cleared-in-progress-content");

export const requestSetDictationMode = action<
  { dictationMode: DictationMode; interactionMethod: InteractionMethod },
  "request-set-dictation-mode"
>("request-set-dictation-mode");
