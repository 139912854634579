import type { SystemState } from "../../../types/SystemState";
import type { PayloadAction } from "@reduxjs/toolkit";

type SetAppVersion = (
  state: SystemState,
  action: PayloadAction<string | null>
) => void;

export const setAppVersion: SetAppVersion = (state, { payload }) => {
  state.appVersion = payload;
};
