import type { Result } from "@talktype/types/src/Result";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getDictateToAppPrecedingText } from "@talktype/dictate-to-app/src/sagas/getDictateToAppPrecedingText";
import { getEditorPrecedingText } from "@talktype/editor/src/sagas/results/getEditorPrecedingText";

/**
 * Get Preceding Text
 *
 * Gets the preceding text for a given result.
 *
 * This comes from:
 * - The editor when the target app is TalkType
 * - Internal state based on previous result when the target app is not TalkType
 */
export const getPrecedingText = function* (
  result: Result
): SagaIterator<string> {
  const precedingText: SagaReturnType<
    typeof getEditorPrecedingText | typeof getDictateToAppPrecedingText
  > = result.targetApp.isSelf
    ? yield call(getEditorPrecedingText)
    : yield call(getDictateToAppPrecedingText, result);

  return precedingText;
};
