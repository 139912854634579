import type { SagaIterator } from "redux-saga";

import { all, call } from "redux-saga/effects";

import { suppressKnownWarnings } from "@carescribe/utilities/src/dev/suppressKnownWarnings";
import { syncActionsAcrossWindows } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpColourScheme } from "@talktype/colour-scheme/src/sagas/setUpColourScheme";
import { setUpFeatureFlags } from "@talktype/feature-flags/src/sagas";
import { setUpPWA } from "@talktype/pwa/src/sagas/setUpPWA";
import { setUpSystem } from "@talktype/system";
import { setUpToasts } from "@talktype/toasts/src/sagas/setUpToasts";

import { configureIdleDetection } from "./configureIdleDetection";
import { configurePkce } from "./configurePkce";
import { configureSentry } from "./configureSentry";
import { configureTracking } from "./configureTracking";
import { configureUpdater } from "./configureUpdater";
import { configureUser } from "./configureUser";
import { keepScreenOnDuringDictation } from "./keepScreenOnDuringDictation";
import { logEnvIfDev } from "./logEnvIfDev";
import { notifyInvalidDesktopInstallation } from "./notifyInvalidDesktopInstallation";
import { addEditorToWindowForCypress } from "./testing/addEditorToWindowForCypress";

export const setUpCore = function* (): SagaIterator<void> {
  yield retry(configureSentry);
  yield retry(setUpFeatureFlags);
  yield retry(setUpToasts);
  yield retry(function* () {
    yield call(syncActionsAcrossWindows, { channelName: "talktype" });
  });
  yield retry(setUpSystem);
  yield all(
    [
      addEditorToWindowForCypress,
      configureIdleDetection,
      configurePkce,
      configureTracking,
      configureUpdater,
      configureUser,
      keepScreenOnDuringDictation,
      logEnvIfDev,
      notifyInvalidDesktopInstallation,
      setUpColourScheme,
      setUpPWA,
      suppressKnownWarnings,
    ].map(retry)
  );
};
