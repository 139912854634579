import type {
  KeyboardEventHandler,
  ReactElement,
  MouseEvent,
  KeyboardEvent,
} from "react";

import { useCallback } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { isKeyboardClick } from "@carescribe/utilities/src/isKeyboardClick";

import { MiniPlayerExitBold } from "@talktype/icons/src/MiniPlayerExitBold";

import { messages } from "./messages";
import { StandardButton } from "../StandardButton";

export type ExitDictateAnywhereButtonProps = {
  onClick: (event: MouseEvent | KeyboardEvent) => void;
};

export const ExitDictateAnywhereButton = ({
  onClick,
}: ExitDictateAnywhereButtonProps): ReactElement => {
  const onKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      if (isKeyboardClick(event)) {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <StandardButton
      colour="neutral"
      hierarchy="link"
      size="sm"
      style="icon-only"
      label={messages.label}
      mainIcon={<MiniPlayerExitBold />}
      elementProps={{
        onClick,
        onKeyDown,
        className: createSelectorClassName(
          "exit-dictate-anywhere-button",
          "interactive"
        ),
      }}
    />
  );
};
