import type { SupportedPlatform } from "@talktype/types/src/SupportedPlatform";
import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./compactLayout.module.scss";

export type CompactLayoutProps = {
  dictationControls: ReactNode;
  closeWindowButton: ReactNode;
  exitDictateAnywhereButton: ReactNode;
  platform: SupportedPlatform | null;
  notifications: ReactNode;
};

export const CompactLayout = ({
  dictationControls,
  closeWindowButton,
  exitDictateAnywhereButton,
  platform,
  notifications,
}: CompactLayoutProps): ReactElement => (
  <div className={styles.container}>
    <div
      className={classNames(styles.header, platform ? styles[platform] : "")}
    >
      {exitDictateAnywhereButton}
      {closeWindowButton}
    </div>
    <main className={styles.main} tabIndex={-1}>
      <div>{dictationControls}</div>
      {notifications}
    </main>
  </div>
);
