import type { MouseEvent, KeyboardEvent } from "react";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { requestToggleBlock, editorBlockToggled } from "../actions";

export const handleHeadingButton = function* (
  event: KeyboardEvent | MouseEvent
): SagaIterator<void> {
  yield put(requestToggleBlock("heading"));

  const { payload: headingOn }: SagaReturnType<typeof editorBlockToggled> =
    yield take(editorBlockToggled);

  const interactionMethod: SagaReturnType<
    typeof getInteractionMethodFromEvent
  > = yield call(getInteractionMethodFromEvent, event);

  yield put(
    requestTrackEvent({
      name: "Block Type Toggled",
      data: {
        block: "heading",
        newBlockStatus: headingOn ? "on" : "off",
        interactionMethod,
      },
    })
  );
};
