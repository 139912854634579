import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { finaliseAndStopDictation } from "./finaliseAndStopDictation";
import { finaliseDictation } from "./finaliseDictation";
import { handleFinaliseText } from "./handleFinaliseText";
import { handleInsertTranscript } from "./handleInsertTranscript";
import { manageClearInProgressContent } from "./manageClearInProgressContent";
import { preventInteractions } from "./preventInteractions";

export const setUpResults = function* (): SagaIterator<void> {
  yield all(
    [
      finaliseAndStopDictation,
      finaliseDictation,
      handleFinaliseText,
      handleInsertTranscript,
      manageClearInProgressContent,
      preventInteractions,
    ].map(retry)
  );
};
