import type { CommandConfig } from "../../../../types";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { setBlock } from "../../../../sagas/commands/formatting/setBlock";
import { pressKeys } from "../../../../sagas/commands/insertion/pressKeys/pressKeys";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const headingOn: CommandConfig = createCommandDefinition({
  id: "headingOn",
  matchers: messages.matchers,
  deadline: toSeconds(0.5),
  messages,
  performCommand: {
    talktype: setBlock("heading"),
    app: pressKeys([
      {
        key: "h",
        ctrlKey: getPlatform() === "win",
        metaKey: getPlatform() === "mac",
      },
    ]),
  },
  input: messages.listing,
  track: {
    name: "Block Type Toggled",
    data: { block: "heading", newBlockStatus: "on" },
  },
});
