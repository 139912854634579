import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";
import { Range } from "slate";

import { requestDeleteSelection, selectionDeleted } from "..";
import { getEditor } from "../utils";

/**
 * Manage Deletion
 *
 * Handles requests to delete the selection. Uses the editor's delete method,
 * but only in the case where the selection is expanded, since delete will
 * delete a character when the selection is collapsed.
 */
export const manageDeletion = function* (): SagaIterator<void> {
  yield takeEvery(requestDeleteSelection, function* () {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(selectionDeleted(false));
      return;
    }

    const { selection } = editor;

    if (selection && !Range.isCollapsed(selection)) {
      yield call(editor.delete);
      yield put(selectionDeleted(true));
    } else {
      yield put(selectionDeleted(false));
    }
  });
};
