import type { Callback } from "@carescribe/types";
import type { ReactNode } from "react";

import { Component } from "react";

export type ErrorBoundaryProps = {
  onError: Callback<unknown, void> | null;
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: unknown): void {
    this.props.onError?.(error);

    setTimeout(() => {
      this.setState({ hasError: false });
    }, 0);
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
