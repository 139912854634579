import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  SystemTrayAddConfig,
  SystemTrayItemBySlotId,
  SystemTraySubscribe,
} from "@talktype/types";
import type { SagaIterator, EventChannel } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";

import { messages } from "@talktype/config/src/messages";
import { trayMenuItems } from "@talktype/config/src/trayMenuItems";
import inactiveTrayIconColour from "@talktype/icons/src/tray-icon-inactive--colour.png";
import inactiveTrayIconPlain from "@talktype/icons/src/tray-icon-inactive--plain.png";
import { getIpc } from "@talktype/utilities/src/getIpc";

import { trayAdded } from "./actions";

export const createTrayItemPressedChannel = (
  subscribe: SystemTraySubscribe["v1"]
): EventChannel<PayloadAction> =>
  eventChannel((emit) => {
    const unsubscribe = subscribe((_, id) => emit(id));
    return unsubscribe;
  });

export const baseTemplate: SystemTrayItemBySlotId[] = [
  [trayMenuItems.separator.id, trayMenuItems.separator],
  [trayMenuItems.help.id, trayMenuItems.help],
  [trayMenuItems.quit.id, trayMenuItems.quit],
];

/**
 * Register Tray
 *
 * - Registers the tray icon and menu items.
 * - Listens for tray item presses and dispatches the relevant action as
 *   defined in the config for each item.
 */
export const registerTray = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);
  const add = ipc?.system?.tray?.add?.v1;
  const getPlatform = ipc?.system?.process?.getPlatform?.v1;
  const subscribe = ipc?.system?.tray?.subscribe?.v1;

  if (!add || !getPlatform || !subscribe) {
    return;
  }

  const platform: SagaReturnType<typeof getPlatform> = yield call(getPlatform);

  const isMacOs = platform === "darwin";

  // MacOS has an item to open the app, which is done by default on Windows by
  // clicking on the icon
  const macOsTemplate: SystemTrayItemBySlotId[] = [
    [trayMenuItems.open.id, trayMenuItems.open],
  ];

  const trayConfig: SystemTrayAddConfig = {
    encodedImage: isMacOs ? inactiveTrayIconPlain : inactiveTrayIconColour,
    tooltip: messages.tray.tooltip,
    menuTemplate: isMacOs ? [...macOsTemplate, ...baseTemplate] : baseTemplate,
  };

  const channel: SagaReturnType<typeof createTrayItemPressedChannel> =
    yield call(createTrayItemPressedChannel, subscribe);

  yield takeEvery(channel, function* (action) {
    yield put(action);
  });

  yield call(add, trayConfig);

  yield put(trayAdded());
};
