import type { CommandConfig, Prompt, PromptWithCommand } from "../types";
import type {
  TranscriptSegment,
  IndexedCommandId,
  AppMetadata,
} from "@talktype/types";

import { analysePrompt } from "./analysePrompt";

export type ProcessedTranscript = {
  // Segments is the thing that gets displayed in the document (the dictation)
  segments: TranscriptSegment[];

  // Current prompt is used to display a voice command to the user
  prompt: Prompt | null;
};

/**
 * Process Voice Commands
 *
 * Takes a single transcript segment and processes the voice commands found
 * within it. Processes a single voice command at a time, and then recurses,
 * passing the remainder of the transcript into another call, only in the case
 * where more commands could be processed.
 *
 * E.g. if there are 2 commands in a transcript, but the first one is an open
 * command, the second one should not be interpreted as a command, but as
 * parameters to the first command
 *
 * @example
 * processVoiceCommands({
 *   ...
 *   segment: {
 *     type: "text",
 *     text: "lorem talktype bold on ipsum talktype select lorem",
 *   },
 * });
 *   // command keywords eg. "talktype"
 *   // commands e.g. "bold on"
 * );
 *
 * // returns:
 * {
 *   segments: [
 *     { type: "text", text: "lorem", ... other marks ... },
 *     { type: "action", ... }, // inline command segment insertions
 *     { type: "text", text: " ipsum", ... other marks ... },
 *   ],
 *   prompt: {
 *     instruction: "select testing",
 *     parameters: "testing",
 *     command: { id: "select", ...select command properties ... },
 *   },
 * };
 */
export const processVoiceCommands = ({
  segment,
  promptKeywords,
  commands,
  id,
  targetApp,
}: {
  segment: TranscriptSegment;
  promptKeywords: string[];
  commands: CommandConfig[];
  id: IndexedCommandId;
  targetApp: AppMetadata;
}): ProcessedTranscript => {
  const segmentId: IndexedCommandId = {
    ...id,
    commandIndex: id.commandIndex + 1,
  };

  const result: ProcessedTranscript = {
    segments: [],
    prompt: null,
  };

  if (!("text" in segment) || !segment.text) {
    return result;
  }

  const { hasPrompt, dictation, command, parameters, instruction } =
    analysePrompt({
      text: segment.text,
      promptKeywords,
      commandKeywords: commands.flatMap((command) => command.matchers),
    });

  result.segments = [{ ...segment, text: dictation }];

  if (!hasPrompt) {
    return result;
  }

  result.prompt = { instruction, command: null, parameters };

  const matchedCommand = commands.find(
    (possibleCommand) =>
      command && possibleCommand.matchers.find((matcher) => matcher === command)
  );

  if (!matchedCommand) {
    return result;
  }

  const isIncompleteCommand = hasPrompt && command.length === 0;
  const processingComplete = matchedCommand || isIncompleteCommand;

  const prompt: PromptWithCommand = {
    parameters,
    instruction,
    command: matchedCommand,
  };

  result.prompt = prompt;

  if (!processingComplete) {
    // Recurse in the case where there could be more voice commands to process
    const furtherInterpretation = processVoiceCommands({
      segment: { ...segment, type: "text", text: parameters },
      promptKeywords,
      commands,
      id: segmentId,
      targetApp,
    });

    result.segments.push(...furtherInterpretation.segments);
    result.prompt = furtherInterpretation.prompt;
  }

  // Remove any empty text segments
  result.segments = result.segments.filter(
    (segment) => !(segment.type === "text" && segment.text === "")
  );

  return result;
};
