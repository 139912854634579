import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { requestSetDictationMode } from "./actions";
import { setDictationMode } from "../reducers";

export const manageDictationModeButtons = function* (): SagaIterator<void> {
  yield takeEvery(
    requestSetDictationMode,
    function* ({ payload: { dictationMode, interactionMethod } }) {
      yield put(setDictationMode(dictationMode));

      yield put(
        requestTrackEvent({
          name: "Dictation Mode Toggled",
          data: { dictationMode, interactionMethod },
        })
      );
    }
  );
};
