import type { MoveSelectionInstruction } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { KeyPress } from "@talktype/types";

export const handleDocument = async ({
  instruction: { direction },
  platform,
  pressKeys,
}: {
  instruction: MoveSelectionInstruction & { unit: "document" };
  platform: Extract<Platform, "win" | "mac">;
  pressKeys: (keypresses: KeyPress[]) => Promise<boolean>;
}): Promise<boolean> => {
  const keypressPerPlatform: Record<"mac" | "win", KeyPress> = {
    mac: { key: direction === "end" ? "Down" : "Up", metaKey: true },
    win: { key: direction === "end" ? "End" : "Home", ctrlKey: true },
  };
  const keypresses: KeyPress[] = [keypressPerPlatform[platform]];

  return pressKeys(keypresses);
};
