import type { Layout as LayoutType } from "@talktype/system/src/types/Layout";
import type { ReactElement } from "react";

import { match } from "ts-pattern";

import "./layout.module.scss";

export type LayoutProps = {
  layout: LayoutType;
  compactLayout: ReactElement;
  regularLayout: ReactElement;
};

export const Layout = ({
  layout,
  compactLayout,
  regularLayout,
}: LayoutProps): ReactElement =>
  match(layout)
    .with("regular", () => regularLayout)
    .with("compact", () => compactLayout)
    .exhaustive();
