import type { Block } from "@talktype/types";
import type { BaseRange, Node } from "slate";

import { Editor, Range } from "slate";

import { defaultBlock } from "@talktype/config/src/defaultBlock";
import { isBlock } from "@talktype/utilities/src/guards/isBlock";

import { logError } from "../log";

const getNodeType = (node: Node): string => ("type" in node ? node.type : "");

const getBlockTypeForExpandedSelection = (
  editor: Editor,
  at: BaseRange
): Block => {
  let blockType: Block | null = null;

  for (const [node] of Editor.nodes(editor, { at })) {
    const type = getNodeType(node);

    if (!isBlock(type)) {
      continue;
    }

    if (blockType && blockType !== type) {
      return defaultBlock;
    }

    blockType = type;
  }

  return blockType ?? defaultBlock;
};

const getBlockTypeForCollapsedSelection = (
  editor: Editor,
  at: BaseRange
): Block => {
  const [parentNode] = Editor.parent(editor, at);
  const parentNodeType = getNodeType(parentNode);

  return isBlock(parentNodeType) ? parentNodeType : defaultBlock;
};

/**
 * Get the block type at the current selection.
 *
 * Determines the block type for the current selection,
 * considering both expanded and collapsed selections.
 *
 * - For expanded selections, it checks all blocks within the selection
 *   and returns the block type if they are all the same, otherwise the
 *   default block type.
 * - For collapsed selections, it checks the parent node's block type.
 */
export const getBlockTypeAtSelection = (editor: Editor): Block => {
  try {
    const { selection } = editor;

    if (!selection) {
      return defaultBlock;
    }

    if (Range.isExpanded(selection)) {
      return getBlockTypeForExpandedSelection(editor, selection);
    }

    return getBlockTypeForCollapsedSelection(editor, selection);
  } catch (cause) {
    logError(new Error("Failed to get block type at selection"), { cause });
    return defaultBlock;
  }
};
