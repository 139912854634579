import type { SystemState } from "../../../types/SystemState";
import type { PayloadAction } from "@reduxjs/toolkit";

type SetBuildId = (
  state: SystemState,
  action: PayloadAction<string | null>
) => void;

export const setBuildId: SetBuildId = (state, { payload }) => {
  state.buildId = payload;
};
