import type { WindowLayout } from "@talktype/system/src/types/WindowLayout";

export const compactWindowLayout: WindowLayout = {
  id: "compact",
  bounds: { width: 76, height: 94 },
  minSize: { width: 76, height: 94 },
  maxSize: { width: 256, height: 1000 },
  resizable: false,
  scalesWithZoom: true,
  responsive: true,
  trafficLightsVisible: false,
  maximisable: false,
  fullScreenable: false,
};
